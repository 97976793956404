/* eslint-disable unicorn/prefer-spread */
import {
  Brand,
  Consumer,
  ImageSource,
  LatLng,
  Meter,
  Npo,
  SalesTypeDto,
  Uon,
  UonStandard,
  UonTransactionType,
} from "@earthtoday/contract";
import { action, computed, makeObservable, observable } from "mobx";
import getConfig from "next/config";
import Router from "next/router";

import {
  CardItemTransactionStandardDriver,
  UonCardLocation,
} from "../../components/CardItemTransactionStandard/CardItemTransactionStandard";
import { ITheMessageStore } from "../../components/TheMessage/TheMessageStore";
import { IGiftCodeApi } from "../../shared/apis/GiftCodeApi";
import { buildResizedAwsImageRequest } from "../../shared/helpers/buildAwsImageRelated";
import {
  Direction,
  transformDigitToDegrees,
} from "../../shared/helpers/coordinates";
import { formatDateForUonCard } from "../../shared/helpers/formatDate";
import { UonTransactionSalesType } from "../../shared/models/Uon";
import { GroupProfileType, UserType } from "../../shared/models/User";
import {
  PROMOTION_IMAGE_SQUARE_SHAPE_HEIGHT,
  PROMOTION_IMAGE_SQUARE_SHAPE_WIDTH,
} from "../CardPromotionModel/CardPromotionBaseModel";
import { GivingPresenter } from "../GivingPresenter";
import { IModalStore } from "../ModalStore";
import { ProfilePagePresenter } from "../ProfilePagePresenter";
import { UserModelConnectorDependencies } from "../UserModel";

export class CardItemTransactionStandardPresenter
  implements CardItemTransactionStandardDriver
{
  @observable public card: UonStandard;
  @observable public isMobileDevice: boolean;
  @observable givingCount: number | null = null;
  @observable otherGivingCount: number | null = null;
  @observable giveInputTouched: boolean = false;
  @observable shareActive: boolean = false;
  @observable isSubmiting = false;

  constructor(
    uonStandard: UonStandard,
    private modalStore: Pick<IModalStore, "openLazyModal" | "closeLazyModal">,
    private userProfile: UserModelConnectorDependencies | null,
    private theMessageStore: Pick<ITheMessageStore, "showMessage">,
    private giftCodeApi: Pick<
      IGiftCodeApi,
      "createGiftCode" | "unwrapGiftCode"
    >,
    private flags: {
      giveUonCardEnabled: boolean;
    },
    isMobileDevice: boolean,
    private statistic: {
      totalUons: number;
      totalBlocks: number;
    },
    private resetState: () => void,
    private profileStore: Pick<
      ProfilePagePresenter,
      | "isLastUonCard"
      | "userCounts"
      | "appendUonsTabData"
      | "filterUonsTabData"
      | "createUonModel"
      | "enableGiftLastUon"
    > | null,
    private uonCardLocation: UonCardLocation,
    public zIndex: number = 0,
  ) {
    this.card = uonStandard;
    this.isMobileDevice = isMobileDevice;
    this.givingStore = new GivingPresenter(
      this.card,
      this.modalStore,
      this.userProfile,
      this.theMessageStore,
      this.giftCodeApi,
      this.flags,
      this.isMobileDevice,
      this.statistic,
      this.resetState,
      this.profileStore || null,
    );
    makeObservable(this);
  }

  toJSON(): Uon {
    return this.card;
  }

  @computed get location(): UonCardLocation {
    return this.uonCardLocation;
  }

  @computed get donatedNpo(): Npo | null {
    return this.card.donatedNpo || null;
  }

  designedBy: string = "Union of Nature";
  designedByUrl: string = "/unionofnature";
  @action.bound onDesignedByClicked(): void {
    this.resetState();
    Router.push(this.designedByUrl, undefined, { shallow: true });
  }

  @computed get protectedBy(): string {
    return this.card.donatedNpo?.name || "";
  }
  @computed get protectedByUrl(): string {
    return "/" + this.card.donatedNpo?.vanityName || "";
  }
  @action.bound onProtectedByClicked(): void {
    this.resetState();
    Router.push(this.protectedByUrl, undefined, { shallow: true });
  }

  @computed get vanityNameDonatedBy(): string {
    if (this.userProfile?.userType === UserType.BRAND) {
      return this.userProfile?.vanityName;
    }
    return (
      this.card.donatorConsumer?.vanityName ||
      this.card.donatorBrand?.vanityName ||
      this.card.owner?.vanityName ||
      ""
    );
  }

  @computed get vanityNameRegisteredTo(): string {
    if (
      this.userProfile &&
      [UserType.BRAND, UserType.CHARITY, UserType.UNCLASSIFIED_ORG].includes(
        this.userProfile.userType,
      )
    ) {
      return this.userProfile.vanityName;
    }

    return this.card.owner?.vanityName || "";
  }
  @computed get vanityNameProtectedBy(): string {
    return this.card.donatedNpo?.vanityName || "";
  }
  @computed get owner(): Consumer | null {
    return this.card.owner || null;
  }

  @computed get donatorBrand(): Brand | null {
    return this.card.donatorBrand || null;
  }

  @computed get meter(): Meter | null {
    return this.card.meter || null;
  }

  @computed get donatorConsumer(): Consumer | null {
    return this.card.donatorConsumer || null;
  }

  @computed get reserveImageUrl(): string {
    if (!this.card.reserveImage) {
      return this.card.reserveImageUrl || "";
    }

    const imageData = {
      ...this.card.reserveImage,
      awsBucket: this.card.reserveImage.bucket,
      awsKey: this.card.reserveImage.key,
      url: this.card.reserveImage.baseUrl,
      isTransparent: false,
    };
    return buildResizedAwsImageRequest(
      imageData,
      {
        width: PROMOTION_IMAGE_SQUARE_SHAPE_WIDTH,
        height: PROMOTION_IMAGE_SQUARE_SHAPE_HEIGHT,
      },
      this.card.reserveImage.baseUrl,
    );
  }

  @computed get id(): string {
    return this.card.id || "";
  }

  @computed get uonReserveSize(): number {
    return this.card?.count || 0;
  }

  @computed get point(): LatLng | null {
    return this.card.point || null;
  }

  @computed get sponsoredBy(): string {
    if (this.userProfile?.userType === UserType.BRAND) {
      return this.userProfile?.fullName;
    }
    return (
      this.card.donatorConsumer?.alias ||
      this.card.donatorBrand?.name ||
      this.card.owner?.alias ||
      ""
    );
  }

  @computed get sponsoredByUrl(): string {
    if (this.userProfile?.userType === UserType.BRAND) {
      return "/" + this.userProfile.vanityName;
    }

    const vanityName =
      this.card.donatorConsumer?.vanityName ||
      this.card.donatorBrand?.vanityName ||
      this.card.owner?.vanityName ||
      "";

    return "/" + vanityName;
  }

  @action.bound onSponsoredByClicked(): void {
    this.resetState();
    Router.push(this.sponsoredByUrl, undefined, { shallow: true });
  }

  @computed get userFullName(): string {
    return this.userProfile?.fullName || "";
  }

  @computed get userPhoto(): string {
    return this.userProfile?.image || "";
  }

  @computed get registeredTo(): string {
    if (
      this.userProfile &&
      [UserType.BRAND, UserType.CHARITY, UserType.UNCLASSIFIED_ORG].includes(
        this.userProfile.userType,
      )
    ) {
      return this.userProfile.fullName;
    }

    return this.card.owner?.alias || this.userProfile?.fullName || "";
  }
  @computed get registeredToUrl(): string {
    if (
      this.userProfile &&
      [UserType.BRAND, UserType.CHARITY, UserType.UNCLASSIFIED_ORG].includes(
        this.userProfile.userType,
      )
    ) {
      return "/" + this.userProfile.vanityName;
    }

    return (
      "/" + this.card.owner?.vanityName || this.userProfile?.vanityName || ""
    );
  }

  @action.bound onRegisteredToClicked(): void {
    this.resetState();
    Router.push(this.registeredToUrl, undefined, { shallow: true });
  }

  @computed get userType(): UserType {
    return this.userProfile?.userType || UserType.CONSUMER;
  }

  @computed get groupRole(): GroupProfileType | undefined {
    return this.userProfile?.groupProfileType;
  }

  @computed get isEndWithOneUonCard(): boolean {
    const splitNumber = String(this.uonReserveSize).split("");
    return Number(splitNumber[splitNumber.length - 1]) === Number(1);
  }

  @computed get isEndWithOne(): boolean {
    const splitNumber = String(this.givingCount || "").split("");
    return Number(splitNumber[splitNumber.length - 1]) === Number(1);
  }

  @computed get giftCode(): string {
    return this.card.code || "";
  }

  @computed get numberOfUon(): number {
    return this.givingCount || 1;
  }

  imageSource = ImageSource.RESERVE_IMAGE;

  @computed get pathSharing(): string {
    const data: Record<string, string> = {
      code: this.giftCode,
    };
    const queryString = new URLSearchParams(data);

    return `${getConfig().publicRuntimeConfig.REACT_APP_HOST}/collect/${
      this.userProfile?.vanityName
    }?${queryString.toString()}`;
  }

  @computed get shareText(): string {
    return "modal-gift.share-text";
  }

  @computed get type(): UonTransactionType {
    return this.card.type || UonTransactionType.DONATE;
  }

  @computed get coordinates(): string {
    return `${transformDigitToDegrees(
      this.point?.lat || 0,
      Direction.Latitude,
    )} 
    -
     ${transformDigitToDegrees(this.point?.lng || 0, Direction.Longitude)}`;
  }

  @computed get issuedAt(): string {
    return formatDateForUonCard(this.card.createdAt);
  }

  @computed get isGiveButtonEnable(): boolean {
    if (this.statistic.totalUons === 1 && this.userProfile?.enableGiftLastUon) {
      return true;
    }
    return this.statistic.totalUons > 1;
  }

  @computed get isGiftButtonVisible(): boolean {
    return (
      this.flags.giveUonCardEnabled &&
      this.salesType.toLowerCase() ===
        UonTransactionSalesType.RETAIL.toLowerCase() &&
      this.card.type?.toLowerCase() !==
        UonTransactionType.PRE_ALLOCATION.toLowerCase()
    );
  }

  @computed get reserveName(): string {
    const reserveName = this.card.meter?.reserve.name || "";
    return reserveName ? reserveName.replaceAll(" ", "") + "_uon" : "";
  }

  @computed get reserveDeckUrl(): string {
    // TODO: enhance get promotion endponit to respond reserve deck
    return "/" + this.card.donatedNpo?.vanityName || "";
  }

  @computed get giftExpiredAt(): Date | null {
    if (!this.card.giftExpiredAt) {
      return null;
    }

    return new Date(this.card.giftExpiredAt);
  }

  @computed get isGiftActive(): boolean {
    if (!this.giftExpiredAt) return false;

    return this.giftExpiredAt > new Date();
  }

  @computed get numberOfUons(): string {
    return this.card.count?.toString() || "0";
  }

  @computed get topRightNote(): string {
    if (this.card.point) {
      return `${transformDigitToDegrees(
        this.card.point?.lat,
        Direction.Latitude,
      )} - ${transformDigitToDegrees(
        this.card.point?.lng,
        Direction.Longitude,
      )}`;
    }
    return "";
  }

  @computed get createdBy(): string {
    return this.card.owner?.vanityName || this.card.owner?.alias || "";
  }
  @action.bound onGiveBtnClicked(): void {
    this.openGivingModal();
  }

  @computed get salesType(): SalesTypeDto {
    return this.card.salesType || SalesTypeDto.RETAIL;
  }

  givingStore: GivingPresenter;

  @action.bound openGivingModal = (options?: { fadeAnimaion?: boolean }) => {
    this.givingStore.openGivingModal(options);
  };

  @action.bound onVistButtonClicked() {
    this.resetState();
  }
}
