/* eslint-disable class-methods-use-this */
import { action, flow, makeObservable, observable } from "mobx";
import { toFlowGeneratorFunction } from "to-flow-generator-function";
import { v4 as uuidv4 } from "uuid";

import { NewVisitorOverlayDriver } from "../../components/NewVisitorOverlay/NewVisitorOverlay";
import { TheNavbarStore } from "../../components/TheNavbarTop/TheNavbarStore/TheNavbarStore";
import { HomeApi } from "../../shared/apis/HomeApi";
import {
  ACCESS_TOKEN_KEY,
  OVERLAY_CLOSED_AT_SESSION_ID,
  SESSION_ID_ON_RELOAD,
} from "../../shared/constants";
import { ETLocalStorage } from "../../shared/helpers/EtStorages";
import { FeatureFlaggingData } from "../FeatureFlaggingStore";
import { FlyingOverPresenter } from "../FlyingOverPresenter/FlyingOverPresenter";

export class NewVisitorOverlayPresenter implements NewVisitorOverlayDriver {
  constructor(
    private homeApi: HomeApi,
    private featureFlagging: FeatureFlaggingData,
    private theNavbarStore: TheNavbarStore,
  ) {
    makeObservable(this);
    this.flyingMapDriver = new FlyingOverPresenter();
  }

  @observable protectedCount: number | null = null;

  @action.bound onUserSessionStateUpdated = flow(function* onMount(
    this: NewVisitorOverlayPresenter,
    pageName: string,
    didInit: boolean,
  ) {
    const newBrowsingSessionId = `${new Date().toISOString()}-${uuidv4()}`;
    ETLocalStorage.setItem(SESSION_ID_ON_RELOAD, newBrowsingSessionId);

    const isUserLoggedIn = !!ETLocalStorage.getItem(ACCESS_TOKEN_KEY);
    const sessionIdOnReload = ETLocalStorage.getItem(SESSION_ID_ON_RELOAD);
    const overlayCloseAtSessionId = ETLocalStorage.getItem(
      OVERLAY_CLOSED_AT_SESSION_ID,
    );
    const isTheSameSession =
      !!overlayCloseAtSessionId &&
      sessionIdOnReload === overlayCloseAtSessionId;
    const isEnabledQrCode = this.featureFlagging.flags.enableLoginWithQRCode;

    if (
      !didInit ||
      !isEnabledQrCode ||
      isUserLoggedIn ||
      isTheSameSession ||
      pageName === "GenericCollectPage" ||
      pageName === "WelcomePage"
    ) {
      return;
    }

    const uonCount = yield* toFlowGeneratorFunction(
      this.homeApi.fetchProtectedCount,
    )();
    this.protectedCount = uonCount;

    this.onOpenOverlay();
  });

  @observable shouldShowOverlay: boolean = false;
  @action.bound onOpenOverlay(): void {
    this.shouldShowOverlay = true;
    document.body.style.overflowY = "hidden";
  }
  @action.bound onCloseOverlay(): void {
    this.shouldShowOverlay = false;
    ETLocalStorage.setItem(
      OVERLAY_CLOSED_AT_SESSION_ID,
      this.browsingSessionId,
    );
    document.body.style.overflowY = "auto";
  }

  @action.bound onCloseButtonClicked(): void {
    this.onCloseOverlay();
  }

  flyingMapDriver: FlyingOverPresenter;

  @observable shouldRenderVideoPlayer: boolean = false;
  @action.bound onWatchVideoButtonClicked(): void {
    this.shouldRenderVideoPlayer = true;
  }
  @action.bound onVideoOverlayClicked(): void {
    this.shouldRenderVideoPlayer = false;
  }

  @action.bound onVisitEarthtodayBtnClicked(): void {
    this.onCloseOverlay();
  }
  @action.bound onLoginForPartnerClicked(isUnitTest?: boolean): void {
    this.theNavbarStore.openQrCodeOverlay(isUnitTest);
    this.onCloseOverlay();
  }

  get browsingSessionId(): string {
    return ETLocalStorage.getItem(SESSION_ID_ON_RELOAD) || "";
  }
}
