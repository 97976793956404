/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable unicorn/prefer-spread */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  ImageSource,
  PaymentFinalizeResponseType,
  PaymentRegion,
  PaymentResponse,
  PaymentResponseDto,
  PaymentStatus,
  PromotionImageShape,
  PromotionSeriesType,
  SalesTypeDto,
  Uon,
  UonCollectable,
  UonTransactionType,
} from "@earthtoday/contract";
import { CardElement } from "@stripe/react-stripe-js";
import { Stripe, StripeCardElement, StripeElements } from "@stripe/stripe-js";
import {
  action,
  computed,
  flow,
  IObservableArray,
  makeObservable,
  observable,
} from "mobx";
import Router from "next/router";
import { ReactNode } from "react";
import { toFlowGeneratorFunction } from "to-flow-generator-function";

import { CardItemTransactionCollectable } from "../../components/CardItemTransactionCollectable/CardItemTransactionCollectable";
import { UonCardLocation } from "../../components/CardItemTransactionStandard/CardItemTransactionStandard";
import { DynamicLazyModalLoader } from "../../components/DynamicModalLoader/DynamicLazyModalLoader";
import { IUserSessionStore } from "../../components/ModalLogin/UserSessionStore";
import {
  OneFlowInitialState,
  OneFlowRedirectionConfig,
  OneFlowRedirectMode,
} from "../../components/ModalOneFlow/ModalOneFlow";
import {
  ErrorMessage,
  ITheMessageStore,
} from "../../components/TheMessage/TheMessageStore";
import { UONReserveDriver } from "../../components/UONReserve/UONReserve";
import { IGiftCodeApi } from "../../shared/apis/GiftCodeApi";
import {
  IPaymentApi,
  isOneFlowFinalizePartialSuccessResponse,
  PaymentMethodResponse,
  PaymentProvider,
  SourcePayload,
  SourceType,
} from "../../shared/apis/PaymentApi";
import { IReserveApi } from "../../shared/apis/ReserveApi";
import { m2Price, priceForVAT } from "../../shared/constants";
import { resolvePaymentMethods } from "../../shared/helpers/resolvePaymentMethods";
import { translateAPIError } from "../../shared/helpers/translateApiError";
import {
  NewGroupPayload,
  OneFlowActAs,
  OneFlowDonationType,
  OneFlowPaymentRetryPayload,
  PaymentMethods,
  PreparePaymentPayload,
  UserRegisterPayload,
} from "../../shared/models/OneFlow";
import {
  ErrorCodeStripeCard,
  OneFlowSessionSource,
  Payment,
  PaymentMethod,
} from "../../shared/models/Payment";
import { ReserveResponse } from "../../shared/models/Reserve";
import { GroupProfileType, UserType } from "../../shared/models/User";
import {
  snowplowCaptureCodeIssuedEvent,
  snowplowCaptureUonProtectedEvent,
} from "../../shared/snowplow";
import { FeatureFlaggingData } from "../../stores/FeatureFlaggingStore";
import { CardItemTransactionCollectableModel } from "../CardItemTransactionCollectableModel/CardItemTransactionCollectableModel";
import { CardItemTransactionStandardPresenter } from "../CardItemTransactionStandardPresenter/CardItemTransactionStandardPresenter";
import { IDeviceStore } from "../DeviceStore";
import { CardItemTransactionModel } from "../GivingPresenter";
import { GroupPresenter } from "../Group/GroupPresenter/GroupPresenter";
import {
  OneFlowScreen,
  OneFlowType,
} from "../ModalOneFlowPresenter/ModalOneFlowPresenter";
import { IModalStore } from "../ModalStore";
import { ProfilePagePresenter } from "../ProfilePagePresenter";
import { UserModel } from "../UserModel";

export const uonSizeOptions = [1, 5, 10, 20, 50, 100, 500, 1000, 1500, 2000];

export type PaymentContext = {
  context: "payments";
  provider: PaymentProvider.MOLLIE;
  id: string;
  token: string;
  sourceId?: string;
  sourceType?: string;
  numberOfUon?: number;
};

export enum UserZone {
  EU = "EU",
  OTHER = "OTHER",
}
export enum Currency {
  USD = "USD",
  EUR = "EUR",
}

export type PaymentContextOneFlow = PaymentContext & { oneFlowId: string };

export type UonReserveInfo = {
  reserveName: string;
  country: string;
};

export const ErrorCode = [
  400, 401, 403, 404, 405, 409, 410, 415, 422, 429, 500, 502, 503,
];

export enum DeclineCode {
  CardNotSupported = "card_not_supported",
  ExpiredCard = "expired_card",
  InsufficientFunds = "insufficient_funds",
}

export enum DonateScreen {
  SelectAmount = "select_amount",
  PaymentDetails = "payment_details",
  Success = "success",
  SelectMethod = "SelectMethod",
  Login = "login",
  Signup = "signup",
  SignupWithEmail = "signup_with_email",
  ResentPassword = "resent_password",
  PreSelection = "pre_selection",
}

export type DonateData = {
  screen: DonateScreen;
  isJustDonated: boolean;
  paymentResponse: PaymentResponseDto | null;
  successScreenLoading: boolean;
  errorCodeIdealPayment: number;
  reserve: ReserveResponse | null;
};

export interface CharityProfile {
  name: string;
  id: string;
}

export type UonSize = number | "custom";
export type IProfileInfo = Pick<
  ProfilePagePresenter,
  | "isLastUonCard"
  | "userCounts"
  | "appendUonsTabData"
  | "filterUonsTabData"
  | "createUonModel"
  | "groupId"
  | "isRequestingPublishGroup"
  | "userProfile"
  | "onDeletePromotion"
  | "enableGiftLastUon"
>;

export type Bank = {
  id: string;
  image: {
    size1x: string;
    size2x: string;
    svg: string;
  };
  name: string;
  resource: string;
};

export class DonateStore {
  @observable screen: DonateScreen = DonateScreen.SelectAmount;
  @observable size: UonSize = uonSizeOptions[1];
  @observable sizeOption: number = uonSizeOptions[1];
  @observable customSize: string = "";
  @observable method: PaymentMethod | null = null;
  @observable selectFormSubmitting: boolean = false;
  @observable selectMethodScreenError: ErrorMessage = null;
  @observable errorCodeIdealPayment: number = 0;
  @observable errorCodeStripeCard: null | string | ReactNode = null;
  @observable banks: Bank[] = [];
  @observable payment: PaymentResponse | null = null;
  @observable idealCardSubmitting: boolean = false;
  @observable stripeCardSubmitting = false;
  @observable paymentResponse: PaymentResponseDto | null = null;
  @observable reserve: ReserveResponse | null = null;
  @observable successScreenLoading = false;
  @observable cardElementComplete: boolean = false;
  @observable showBankList: boolean = false;
  @observable bankSelected: Bank | null = null;
  @observable preSelectSize: UonSize | null = null;
  @observable charityProfile: CharityProfile | null = null;
  @observable paymentStatus: PaymentStatus | null = null;
  @observable campaignSource: {
    fullName: string;
    campaignId: string;
  } | null = null;
  @observable isJustDonated: boolean = false;
  @observable isCampaignHeaderCard: boolean = false;
  @observable isRequestingPublishGroup = false;

  // * use to update profileStore after publish group by donating through Mollie
  @observable isPublishGroupSuccessful = false;
  @observable npoId: string = "";
  @observable promotionId: string = "";
  @observable reserveId: string = "";
  @observable isDonateHomeHeaderCard: boolean = false;
  @observable oneFlowId: string = "";
  @observable oneFlowDonationTypeFromResponse: PaymentFinalizeResponseType =
    PaymentFinalizeResponseType.PROTECT;
  @observable oneFlowShareId: string = "";
  @observable errorMollieResponse: { error: string; status?: number } | null =
    null;

  @observable isPreparingAccount: boolean = false;
  @observable paymentMethods: IObservableArray<PaymentMethod> =
    observable<PaymentMethod>([]);

  @observable isRetryPaymentOneFlow: boolean = false;

  @observable protectedByPersonalSubscription: string = ""; //vanityName of NPO

  @observable triggerPointDataCy: string = "";

  constructor(
    public paymentAPI: IPaymentApi,
    private reserveAPI: IReserveApi,
    private giftApi: IGiftCodeApi,
    public modalStore: IModalStore,
    private userSessionStore: IUserSessionStore,
    private theMessageStore: ITheMessageStore,
    private featureFlaggingStore: FeatureFlaggingData,
    private deviceStore: IDeviceStore,
    private profileStore: IProfileInfo | null,
    private groupStore: GroupPresenter,
    private getCurrentUrl: () => string,
    private clearUserCountsCacheProfile?: () => void,
    isPublishGroup = false,
  ) {
    makeObservable<DonateStore, "paymentKey" | "userFullName">(this);
    this.isRequestingPublishGroup = isPublishGroup;
  }

  public dehydrate(): DonateData {
    return {
      screen: this.screen,
      isJustDonated: this.isJustDonated,
      paymentResponse: this.paymentResponse,
      reserve: this.reserve,

      successScreenLoading: this.successScreenLoading,
      errorCodeIdealPayment: this.errorCodeIdealPayment,
    };
  }

  @action.bound public hydrate(data: DonateData): void {
    this.screen = data.screen;

    this.isJustDonated = data.isJustDonated;
    this.paymentResponse = data.paymentResponse;
    this.reserve = data.reserve;

    this.successScreenLoading = data.successScreenLoading;
    this.errorCodeIdealPayment = data.errorCodeIdealPayment;
  }

  @action.bound
  checkJustDonated(): boolean {
    if (this.isJustDonated === true) {
      this.isJustDonated = false;
      return true;
    }

    return false;
  }

  @action updateCardElementComplete = (b: boolean): void => {
    this.cardElementComplete = b;
  };

  @action updateShowBankList = (): void => {
    this.showBankList = !this.showBankList;
  };

  @action.bound onClickIconClose(): void {
    if (this.screen === DonateScreen.PreSelection) {
      this.modalStore.openModal("");
      this.screen = DonateScreen.SelectAmount;
      this.charityProfile = null;
      this.campaignSource = null;
      this.npoId = "";
      this.preSelectSize = null;
      this.method = null;
      this.showBankList = false;
      this.isDonateHomeHeaderCard = false;
      return;
    }
    if (
      this.screen === DonateScreen.SelectAmount &&
      this.hasPrevSelectionWindow
    ) {
      this.screen = DonateScreen.PreSelection;
      this.preSelectSize = null;
      return;
    }

    if (
      this.screen === DonateScreen.Login ||
      (this.screen === DonateScreen.PaymentDetails &&
        this.errorCodeIdealPayment === 0 &&
        this.errorCodeStripeCard === null &&
        this.paymentStatus === null)
    ) {
      this.screen = DonateScreen.SelectAmount;
      return;
    }

    if (
      this.screen === DonateScreen.Signup ||
      this.screen === DonateScreen.ResentPassword
    ) {
      this.screen = DonateScreen.Login;
      return;
    }

    if (this.screen === DonateScreen.SignupWithEmail) {
      this.screen = DonateScreen.Signup;
      return;
    }

    // only reset all if payment has success or error
    if (
      this.screen === DonateScreen.Success ||
      this.screen === DonateScreen.SelectAmount ||
      this.errorCodeIdealPayment !== 0 ||
      this.errorCodeStripeCard !== null ||
      this.paymentStatus !== null
    ) {
      this.modalStore.openModal("");
      this.screen = DonateScreen.SelectAmount;
      this.size = uonSizeOptions[1];
      this.sizeOption = uonSizeOptions[1];
      this.customSize = "";
      this.payment = null;
      this.paymentResponse = null;
      this.preSelectSize = null;
      this.charityProfile = null;
      this.campaignSource = null;
      this.npoId = "";
    }

    this.selectMethodScreenError = null;
    this.errorCodeIdealPayment = 0;
    this.errorCodeStripeCard = null;
    this.cardElementComplete = false;
    this.paymentStatus = null;
    this.isRetryPaymentOneFlow = false;
  }

  @action updateBank = (bank: Bank): void => {
    this.bankSelected = bank;
    this.showBankList = false;
  };

  @action.bound onClose(): void {
    this.modalStore.openModal("");
    this.resetOnClickOutSide();
  }

  @computed get donateSize(): number {
    if (this.size === "custom") {
      return +this.customSize;
    }

    return this.size;
  }
  @computed get renderUonSizeOptions(): number[] {
    const index = this.size
      ? uonSizeOptions.indexOf(this.sizeOption)
      : uonSizeOptions.indexOf(this.sizeOption);
    if (index === uonSizeOptions.length - 2) {
      return uonSizeOptions.slice(index).concat(uonSizeOptions.slice(0, 1));
    }
    if (index === uonSizeOptions.length - 1) {
      return uonSizeOptions.slice(index).concat(uonSizeOptions.slice(0, 2));
    }
    if (!uonSizeOptions.includes(this.sizeOption)) {
      return uonSizeOptions.slice(0, 3);
    }
    return uonSizeOptions.slice(index, index + 3);
  }
  @action donateEuro = (size: number): number => {
    return size * m2Price;
  };

  @computed get donateTotal(): number {
    return this.donateSize * m2Price;
  }

  @computed get selectFormSubmitBtnEnabled(): boolean {
    if (this.selectFormSubmitting) {
      return false;
    }
    return !!this.donateSize; // not enabled case size = 0
  }

  @computed get hasPrevSelectionWindow(): boolean {
    return (
      this.isCharityDonate ||
      this.isDonateToCampaign ||
      this.isDonateHomeHeaderCard
    );
  }
  @computed get npoLogoUrl(): string {
    return (
      (this.paymentResponse?.transaction?.imageSource ===
        ImageSource.RESERVE_IMAGE &&
        this.paymentResponse?.transaction?.donatedReserve?.npoDetails?.image) ||
      ""
    );
  }
  @computed get npoName(): string {
    return (
      (this.paymentResponse?.transaction?.imageSource ===
        ImageSource.RESERVE_IMAGE &&
        this.paymentResponse?.transaction?.donatedReserve?.npoDetails?.name) ||
      ""
    );
  }
  @computed get uonReserve(): UONReserveDriver | null {
    if (this.paymentResponse) {
      return {
        count: this.paymentResponse.numberOfUon,
        point: (this.paymentResponse?.transaction?.imageSource ===
          ImageSource.RESERVE_IMAGE &&
          this.paymentResponse?.transaction?.donatedReserve?.point) || {
          lat: 0,
          lng: 0,
        },
        reserveImageUrl:
          (this.paymentResponse?.transaction?.imageSource ===
            ImageSource.RESERVE_IMAGE &&
            this.paymentResponse?.transaction?.donatedReserve?.imageUrl) ||
          "",
      };
    }
    return null;
  }

  @computed private get paymentKey(): string | null {
    return this.payment && this.payment.provider === PaymentProvider.STRIPE
      ? this.payment.key || null
      : null;
  }

  @computed get userFullName(): string {
    return this.userSessionStore.user
      ? this.userSessionStore.user.fullName
      : "";
  }

  @computed get uonReserveSize(): number {
    return this.paymentResponse ? this.paymentResponse.numberOfUon : 0;
  }

  @computed get numberOfUon(): number {
    return this.paymentResponse ? this.paymentResponse.numberOfUon : 0;
  }

  @computed get responseDonateSuccess(): CardItemTransactionModel | null {
    if (
      !this.paymentResponse ||
      this.oneFlowDonationTypeFromResponse !== OneFlowDonationType.PROTECT
    ) {
      return null;
    }

    //TODO: DRY this with collect success
    if (
      this.paymentResponse?.transaction?.imageSource === ImageSource.UON_IMAGE
    ) {
      const card: UonCollectable = {
        id: this.paymentResponse?.transaction?.id || "",
        count: this.paymentResponse?.numberOfUon || 0,
        createdAt: new Date().toString(),
        meter: {
          reserve: {
            name: this.paymentResponse?.transaction?.reserveName || "",
            created: new Date().toString(),
            id: "",
            perimeterStatus: "",
          },
          status: "",
        },
        point: this.paymentResponse?.transaction?.point || {
          lat: 0,
          lng: 0,
        },
        donatedNpo: {
          email: "",
          id:
            this.paymentResponse?.transaction?.npoDetails?.earthId?.toString() ||
            "0",
          name: this.paymentResponse?.transaction?.npoDetails?.name || "",
          state: "",
          vanityName:
            (this.paymentResponse?.transaction?.deckDetails?.path &&
              this.paymentResponse?.transaction?.deckDetails?.path[0]) ||
            "",
          coverImage:
            this.paymentResponse?.transaction?.npoDetails?.coverImage || "",
        },
        owner: {
          alias: this.userSessionStore.user?.fullName || "",
          vanityName: this.userSessionStore.user?.vanityName || "",
          email: this.userSessionStore.user?.emailAddress || "",
          id: this.userSessionStore.user?.id || "0",
        },
        reserveImageUrl: this.paymentResponse.transaction.imageUrl || "",
        type: UonTransactionType.CLAIM,
        salesType: SalesTypeDto.RETAIL,
        imageSource: ImageSource.UON_IMAGE,
        uonImage: this.paymentResponse.transaction.uonImage,
        collectionName: this.paymentResponse.transaction.collectionName,
        uonImageShape: this.paymentResponse.transaction.uonImageShape,
        series: this.paymentResponse.transaction.series,
        collectionCreator: this.paymentResponse.transaction.collectionCreator,
      };

      return new CardItemTransactionCollectableModel(
        card,
        this.modalStore,
        this.userSessionStore.user,
        this.theMessageStore,
        this.giftApi,
        this.featureFlaggingStore.flags,
        this.deviceStore.isMobileDevice,
        {
          totalUons:
            (this.userSessionStore.user?.userCounts.uonCount || 0) === 1
              ? 1
              : 2,
          totalBlocks: 0,
        },
        this.resetOnClickOutSide,
        this.profileStore,
        UonCardLocation.DONATION_SUCCESS,
      );
    }

    if (
      this.paymentResponse?.transaction?.imageSource ===
      ImageSource.RESERVE_IMAGE
    ) {
      const card = {
        id: this.paymentResponse?.transaction?.id || "",
        count: this.paymentResponse?.numberOfUon || 0,
        createdAt: new Date().toString(), // not use
        meter: {
          reserve: {
            name: this.reserve?.name || "",
            created: new Date().toString(),
            id: "",
            perimeterStatus: "",
          },
          status: "",
        },
        point: this.paymentResponse?.transaction?.donatedReserve?.point || {
          lat: 0,
          lng: 0,
        },
        donatedNpo: {
          email: "",
          id:
            this.paymentResponse?.transaction?.donatedReserve?.npoDetails?.earthId?.toString() ||
            "0",
          name:
            this.paymentResponse?.transaction?.donatedReserve?.npoDetails
              ?.name || "",
          state: "",
          vanityName:
            (this.paymentResponse?.transaction?.donatedReserve?.deckDetails
              ?.path &&
              this.paymentResponse?.transaction?.donatedReserve?.deckDetails
                ?.path[0]) ||
            "",
          coverImage:
            this.paymentResponse?.transaction?.donatedReserve?.npoDetails
              ?.coverImage,
        },
        owner: {
          alias: this.userSessionStore.user?.fullName || "",
          vanityName: this.userSessionStore.user?.vanityName || "",
          email: this.userSessionStore.user?.emailAddress || "",
          id: this.userSessionStore.user?.id || "0",
        },
        reserveImageUrl:
          this.paymentResponse?.transaction?.donatedReserve?.imageUrl || "",
        reserveImage:
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.paymentResponse!.transaction!.donatedReserve!.reserveImage,
        type: UonTransactionType.CLAIM,
        salesType: SalesTypeDto.RETAIL,
        imageSource: ImageSource.RESERVE_IMAGE,
      };
      return new CardItemTransactionStandardPresenter(
        card,
        this.modalStore,
        this.userSessionStore.user,
        this.theMessageStore,
        this.giftApi,
        this.featureFlaggingStore.flags,
        this.deviceStore.isMobileDevice,
        {
          totalUons:
            (this.userSessionStore.user?.userCounts.uonCount || 0) === 1
              ? 1
              : 2,
          totalBlocks: 0,
        },
        this.resetOnClickOutSide,
        null,
        UonCardLocation.DONATION_SUCCESS,
      );
    }
    return null;
  }

  @computed get uonReserveName(): string {
    const path =
      (this.paymentResponse?.transaction?.imageSource ===
        ImageSource.RESERVE_IMAGE &&
        this.paymentResponse?.transaction?.donatedReserve?.deckDetails?.path) ||
      [];
    return path[1];
  }

  @computed get donationStatus(): string {
    return this.paymentResponse ? this.paymentResponse?.status : "";
  }

  @action.bound updateNpoId = (id: string) => {
    this.npoId = id;
  };

  @action.bound updatePromotionId = (id: string) => {
    this.promotionId = id;
  };

  @action.bound updateReserveId = (id: string) => {
    this.reserveId = id;
  };

  // * finalize payment after redirect from Mollie
  fetchPaymentStatus = flow(function* fetchPaymentStatus(
    this: DonateStore,
    context: PaymentContext,
  ) {
    this.errorCodeIdealPayment = 0;
    const donatedNumber = context.numberOfUon || 0;
    if (uonSizeOptions.includes(donatedNumber)) {
      this.size = donatedNumber;
    } else {
      this.size = "custom";
      this.customSize = donatedNumber.toString();
    }
    try {
      this.successScreenLoading = true;
      this.modalStore.openModal("donate");
      this.screen = DonateScreen.Success;

      if (context.sourceType === SourceType.NPO) {
        this.updateNpoId(context.sourceId || "");
      }

      const finalizePaymentResponse =
        yield this.paymentAPI.getPaymentResponseMollie(
          context.id,
          context.token,
        );

      if (finalizePaymentResponse.authorization !== "") {
        yield this.userSessionStore.initLoginStatus({ force: true });
      }

      const paymentResponse = finalizePaymentResponse.res;

      if (
        !!this.paymentStatus &&
        this.paymentStatus !== PaymentStatus.DONATED &&
        this.paymentStatus !== PaymentStatus.UON_QUEUED &&
        this.paymentStatus !== PaymentStatus.SUCCEEDED
      ) {
        this.screen = DonateScreen.PaymentDetails;
      }

      if (paymentResponse?.transaction?.donatedReserve?.deckDetails?.path[1]) {
        const reserveResponse = yield this.reserveAPI.fetchReserve(
          paymentResponse?.transaction?.donatedReserve.deckDetails.path[1] ||
            "",
        );
        this.reserve = reserveResponse;
      }

      this.paymentResponse = paymentResponse;
      this.paymentStatus = paymentResponse.status;
      this.subscribeOrPushProtectedEvent();

      // * payment is successful
      // * show message for group publish state
      if (context.sourceType === SourceType.GROUP_PUBLISH) {
        if (this.paymentStatus === PaymentStatus.DONATED) {
          this.isPublishGroupSuccessful = true;
          this.theMessageStore.showMessage({
            typeMessage: "Close",
            title: "toast-message.group-published.title",
            content: "toast-message.group-published.content",
          });
        } else {
          // * payment is queued
          this.theMessageStore.showMessage({
            typeMessage: "Close",
            title: "toast-message.transaction-processing.title",
            content: "toast-message.transaction-processing.content",
          });
        }
      }
    } catch (error) {
      this.errorCodeIdealPayment = (error as any).response?.status;
      this.screen = DonateScreen.PaymentDetails;

      // * show error when request publish group
      if (context.sourceType === SourceType.GROUP_PUBLISH) {
        this.theMessageStore.showMessage({
          typeMessage: "Error",
          title: "toast-message.general.error",
          content: translateAPIError(error),
        });
      }
    } finally {
      this.successScreenLoading = false;
      this.isJustDonated = true;
    }
  });

  @action.bound resetError = flow(function* resetError(this: DonateStore) {
    if (this.oneFlowId) {
      this.isRetryPaymentOneFlow = true; //detect retry for payment oneFlow
    }

    if (this.errorCodeIdealPayment !== 0 || this.errorMollieResponse !== null) {
      this.method = PaymentMethod.IDeal;
      yield this.getBankList();
      this.showBankList = true;
    } else if (this.errorCodeStripeCard === DeclineCode.CardNotSupported) {
      this.method = PaymentMethod.Card;
    } else {
      this.method = null;
    }
    this.paymentStatus = null;
    this.errorCodeIdealPayment = 0;
    this.errorCodeStripeCard = null;
    this.errorMollieResponse = null;
    this.bankSelected = null;
    this.cardElementComplete = false;
    this.selectMethodScreenError = null;
    this.stripeCardSubmitting = false;
    this.idealCardSubmitting = false;
  });

  @action.bound resetParams = (option?: {
    isResetParamsURLSpecific: boolean;
  }): void => {
    const [asPath, queryString] = Router.asPath.split("?");

    if (!queryString) {
      return;
    }

    const params = new URLSearchParams(queryString);

    const sourceType = params.get("sourceType");
    const sourceId = params.get("sourceId");

    // fullname, name here use for modalPreSelect
    // this case is retry, so no worry about it
    if (sourceId && sourceType && sourceType === SourceType.CAMPAIGN) {
      this.campaignSource = {
        fullName: "",
        campaignId: sourceId,
      };
    }

    if (sourceId && sourceType && sourceType === SourceType.CHARITY) {
      this.charityProfile = {
        name: "",
        id: sourceId,
      };
    }

    params.delete("context");
    params.delete("provider");
    params.delete("id");
    params.delete("token");
    params.delete("sourceId");
    params.delete("sourceType");
    params.delete("numberOfUon");
    params.delete("oneFlowId");
    params.delete("redirectPath");
    params.delete("redirectMode");
    if (option?.isResetParamsURLSpecific) {
      params.delete("oneFlowType");
      params.delete("actAs");
      params.delete("giftsCount");
      params.delete("gifting");
      params.delete("protectSize");
      params.delete("subscribeIntervalValue");
      params.delete("subscribeIntervalUnit");
    }
    const queryParams = params.toString() ? `?${params.toString()}` : "";

    /* prevent reload state */
    window.history.pushState("", "", `${asPath}${queryParams}`);
  };

  @action.bound resetDonationState = flow(function* (this: DonateStore) {
    yield this.resetError();
    this.resetParams();
  });

  @action.bound updateScreen = flow(function* (
    this: DonateStore,
    screen: DonateScreen,
    shouldFetchPaymentMethod: boolean = true,
  ) {
    this.screen = screen;
    this.selectMethodScreenError = null;
    if (
      this.screen === DonateScreen.PaymentDetails &&
      shouldFetchPaymentMethod
    ) {
      yield this.fetchPaymentMethods();
    }
  });

  @action.bound updateUonSize(size: UonSize): void {
    this.size = size;
    if (this.size !== "custom" && !this.isOptionSizeDisabled) {
      this.sizeOption = Number.parseFloat(`${size}`);
    }
  }

  @action.bound updateUonCustomSize(size: string): void {
    this.customSize = size;
  }

  @action.bound onPaymentMethodSelected(method: PaymentMethod): void {
    this.method = method;
    if (method === PaymentMethod.IDeal) {
      this.getBankList();
    }
  }

  @action.bound submitIdealPayment = flow(function* submitIdealPayment(
    this: DonateStore,
  ) {
    if (!this.userSessionStore.user) {
      this.modalStore.openModal("loginForm", {
        keepPreviousModal: true,
      });
      return;
    }
    this.selectMethodScreenError = null;
    this.selectFormSubmitting = true;
    this.idealCardSubmitting = true;

    try {
      this.payment = this.isDonateToCampaign
        ? yield* toFlowGeneratorFunction(
            this.paymentAPI.preparePaymentMollieCampaign,
          )(
            this.donateSize,
            this.bankSelected?.id || "",
            this.currentUrl,
            this.campaignSource?.campaignId || "",
            this.promotionId || undefined,
          )
        : this.isCharityDonate
        ? yield* toFlowGeneratorFunction(
            this.paymentAPI.preparePaymentMollieCharity,
          )(
            this.donateSize,
            this.bankSelected?.id || "",
            this.currentUrl,
            this.charityProfile?.id || "",
            this.promotionId || undefined,
          )
        : this.profileStore?.isRequestingPublishGroup
        ? yield* toFlowGeneratorFunction(
            this.paymentAPI.preparePaymentMollieToPublishGroup,
          )(
            this.donateSize,
            this.bankSelected?.id || "",
            this.currentUrl,
            this.groupId,
            this.promotionId || undefined,
          )
        : this.npoId === ""
        ? this.reserveId === ""
          ? yield* toFlowGeneratorFunction(
              this.paymentAPI.preparePaymentMollieProtect,
            )(
              this.donateSize,
              this.bankSelected?.id || "",
              this.currentUrl,
              this.promotionId || undefined,
            )
          : yield* toFlowGeneratorFunction(
              this.paymentAPI.preparePaymentMollieReserve,
            )(
              this.donateSize,
              this.bankSelected?.id || "",
              this.currentUrl,
              this.reserveId,
            )
        : yield* toFlowGeneratorFunction(
            this.paymentAPI.preparePaymentMollieNpo,
          )(
            this.donateSize,
            this.bankSelected?.id || "",
            this.currentUrl,
            this.npoId,
            this.promotionId || undefined,
          );

      if (this.payment?.checkoutUrl) {
        window.location.href = this.payment.checkoutUrl;
        // reset payment after redirect
        this.payment = null;
        return;
      }

      this.screen = DonateScreen.Success;
    } catch (error: any) {
      this.selectMethodScreenError = translateAPIError(error);
      this.errorCodeIdealPayment = (error as any).response.status;
      this.screen = DonateScreen.PaymentDetails;
    } finally {
      this.selectFormSubmitting = false;
      this.idealCardSubmitting = false;
    }
  });

  @action.bound submitPayDetails(): void {
    this.selectMethodScreenError = null;
    if (!this.userSessionStore.user) {
      this.screen = DonateScreen.Login;
      return;
    }
    this.updateScreen(DonateScreen.PaymentDetails);
  }

  buildPreparePaymentPayloadOneFlow(
    oneFlowType: OneFlowType,
    sourceId?: string,
    sourceType?: SourceType,
    giftsCount?: number,
    redirectionConfig?: OneFlowRedirectionConfig,
  ): PreparePaymentPayload {
    let numberOfUon = this.donateSize;
    if (oneFlowType === OneFlowType.SUBSCRIBE && !!giftsCount) {
      numberOfUon = giftsCount * 12;
    }
    return {
      method:
        this.method === PaymentMethod.Card
          ? PaymentMethods.CREDIT_CARD
          : PaymentMethods.IDEAL,
      numberOfUon,
      redirectUrl: this.currentUrl,
      issuer: this.bankSelected?.id,
      promotionId: this.promotionId || undefined,
      sourceId,
      sourceType,
      redirectUrlExtraParameters: redirectionConfig
        ? {
            redirectPath: redirectionConfig.redirectPath,
            redirectMode: redirectionConfig.redirectMode,
          }
        : undefined,
    };
  }

  getBankList = flow(function* getBankList(this: DonateStore) {
    this.selectMethodScreenError = null;
    const api = this.paymentAPI;
    try {
      this.banks = yield api.getListPaymentMollie();
    } catch (error) {
      this.selectMethodScreenError = translateAPIError(error);
    }
  });

  submitStripeCardForm = flow(function* submitStripeCardForm(
    this: DonateStore,
    stripe: Stripe,
    elements: StripeElements,
  ) {
    if (this.clearUserCountsCacheProfile) {
      this.clearUserCountsCacheProfile();
    }

    this.errorCodeStripeCard = null;
    this.stripeCardSubmitting = true;
    this.selectMethodScreenError = null;

    if (!this.userSessionStore.user) {
      this.modalStore.openModal("loginForm", {
        keepPreviousModal: true,
      });
      this.stripeCardSubmitting = false;
      return;
    }
    const api = this.paymentAPI;

    try {
      this.payment = this.isDonateToCampaign
        ? yield* toFlowGeneratorFunction(api.preparePaymentStripeCampaign)(
            this.donateSize,
            this.campaignSource?.campaignId || "",
            this.promotionId || undefined,
          )
        : this.isCharityDonate
        ? yield* toFlowGeneratorFunction(api.preparePaymentStripeCharity)(
            this.donateSize,
            this.charityProfile?.id || "",
            this.promotionId || undefined,
          )
        : this.profileStore?.isRequestingPublishGroup
        ? yield* toFlowGeneratorFunction(api.preparePaymentStripePublishGroup)(
            this.donateSize,
            this.groupId,
            this.promotionId || undefined,
          )
        : this.npoId === ""
        ? this.reserveId === ""
          ? yield* toFlowGeneratorFunction(api.preparePaymentStripeProtect)(
              this.donateSize,
              this.promotionId || undefined,
            )
          : yield* toFlowGeneratorFunction(api.preparePaymentStripeReserve)(
              this.donateSize,
              this.reserveId,
            )
        : yield* toFlowGeneratorFunction(api.preparePaymentStripeNpo)(
            this.donateSize,
            this.npoId,
            this.promotionId || undefined,
          );
    } catch (error) {
      this.stripeCardSubmitting = false;
      this.selectMethodScreenError = translateAPIError(error);
    }

    const { paymentKey } = this;

    if (!paymentKey) {
      return;
    }
    try {
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement) as StripeCardElement;
      const result = yield stripe.confirmCardPayment(paymentKey, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: this.userFullName,
          },
        },
      });
      if (result.error) {
        if (result.error.code === "card_declined") {
          this.errorCodeStripeCard = result.error.decline_code;
          return;
        }
        this.errorCodeStripeCard = result.error.code;
        return;
      }

      const paymentResponse = yield this.paymentAPI.getPaymentResponseStripe(
        result.paymentIntent.id,
      );
      this.paymentStatus = paymentResponse.status;

      if (paymentResponse?.transaction?.donatedReserve?.deckDetails?.path[1]) {
        const reserveResponse = yield this.reserveAPI.fetchReserve(
          paymentResponse?.transaction?.donatedReserve.deckDetails.path[1] ||
            "",
        );
        this.reserve = reserveResponse;
      }

      this.paymentResponse = paymentResponse;
      this.screen = DonateScreen.Success;
      this.subscribeOrPushProtectedEvent();

      // * show message for publishing group
      if (this.isRequestingPublishGroup) {
        if (this.paymentStatus === PaymentStatus.DONATED) {
          this.isPublishGroupSuccessful = true;
          this.theMessageStore.showMessage({
            typeMessage: "Close",
            title: "toast-message.group-published.title",
            content: "toast-message.group-published.content",
          });
          this.profileStore?.userProfile?.setPublishStatus(true);
        } else {
          // * payment is queued
          this.theMessageStore.showMessage({
            typeMessage: "Close",
            title: "toast-message.transaction-processing.title",
            content: "toast-message.transaction-processing.content",
          });
        }
      }
    } catch (error) {
      this.errorCodeStripeCard = translateAPIError(error);

      // * show error when request publish group
      if (this.isRequestingPublishGroup) {
        this.theMessageStore.showMessage({
          typeMessage: "Error",
          title: "toast-message.general.error",
          content: translateAPIError(error),
        });
      }
    } finally {
      this.stripeCardSubmitting = false;
      this.isJustDonated = true;
      this.isRequestingPublishGroup = false;
    }
  });

  @computed get currentUrl(): string {
    const url = this.getCurrentUrl().replaceAll(/(#).*/g, "");
    const sanitizeUrl = this.removeQueryRarams(url);
    return sanitizeUrl;
  }

  @computed get errorMessageOneFlow(): {
    message: string;
    buttonLabel: string;
  } {
    if (this.method === PaymentMethod.IDeal && !!this.errorMollieResponse) {
      return this.errorMollieResponse.status === 401
        ? {
            message:
              "modal-donation.error-message-oneflow.authentication-failed",
            buttonLabel:
              "modal-donation.error-button-label-oneflow.retry-payment",
          }
        : {
            message: "modal-donation.error-message-oneflow.other-error",
            buttonLabel:
              "modal-donation.error-button-label-oneflow.retry-payment",
          };
    }
    if (this.method === PaymentMethod.Card && this.errorCodeStripeCard) {
      switch (this.errorCodeStripeCard) {
        case ErrorCodeStripeCard.GENERIC_DECLINE: {
          return {
            message: "modal-donation.error-message-oneflow.generic-decline",
            buttonLabel:
              "modal-donation.error-button-label-oneflow.retry-payment",
          };
        }
        case ErrorCodeStripeCard.INSUFFICIENT_FUNDS: {
          return {
            message: "modal-donation.error-message-oneflow.insufficient-funds",
            buttonLabel:
              "modal-donation.error-button-label-oneflow.retry-payment",
          };
        }
        case ErrorCodeStripeCard.EXPIRED_CARD: {
          return {
            message: "modal-donation.error-message-oneflow.expired-card",
            buttonLabel:
              "modal-donation.error-button-label-oneflow.retry-payment",
          };
        }
        case ErrorCodeStripeCard.INCORRECT_CVC: {
          return {
            message: "modal-donation.error-message-oneflow.incorrect-cvc",
            buttonLabel:
              "modal-donation.error-button-label-oneflow.change-card-info",
          };
        }
        case ErrorCodeStripeCard.INCORRECT_NUMBER: {
          return {
            message: "modal-donation.error-message-oneflow.incorrect_number",
            buttonLabel:
              "modal-donation.error-button-label-oneflow.change-card-info",
          };
        }
        case ErrorCodeStripeCard.PROCESSING_ERROR: {
          return {
            message: "modal-donation.error-message-oneflow.processing-error",
            buttonLabel:
              "modal-donation.error-button-label-oneflow.retry-payment",
          };
        }
        default: {
          return {
            message: "modal-donation.error-message-oneflow.other-error",
            buttonLabel:
              "modal-donation.error-button-label-oneflow.retry-payment",
          };
        }
      }
    }
    return {
      message: "modal-donation.error-message-oneflow.other-error",
      buttonLabel: "modal-donation.error-button-label-oneflow.retry-payment",
    };
  }

  @computed get isPaymentOneFlowError(): boolean {
    return (
      !!this.oneFlowId &&
      (!!this.errorCodeIdealPayment ||
        !!this.errorCodeStripeCard ||
        !!this.errorMollieResponse)
    );
  }

  @computed get errorMessage(): string | null {
    if (this.isPaymentOneFlowError) {
      return this.errorMessageOneFlow.message;
    }
    if (this.errorMollieResponse) {
      return this.errorMollieResponse.error;
    }
    if (
      !!this.paymentStatus &&
      this.paymentStatus !== PaymentStatus.DONATED &&
      this.paymentStatus !== PaymentStatus.UON_QUEUED &&
      this.paymentStatus !== PaymentStatus.SUCCEEDED &&
      this.paymentStatus !== PaymentStatus.COLLECTED &&
      this.paymentStatus !== PaymentStatus.CODE_ENQUEUED
    ) {
      return "modal-donation.flow.something-wrong";
    }

    if (
      !this.selectMethodScreenError &&
      !this.errorCodeIdealPayment &&
      !this.errorCodeStripeCard
    ) {
      return null;
    }

    if (ErrorCode.includes(this.errorCodeIdealPayment)) {
      return "modal-donation.flow.payment-error-update";
    }

    if (this.errorCodeStripeCard === DeclineCode.CardNotSupported) {
      return "modal-donation.flow.card-not-supported";
    }

    if (this.errorCodeStripeCard === DeclineCode.ExpiredCard) {
      return "modal-donation.flow.card-expired";
    }

    if (this.errorCodeStripeCard === DeclineCode.InsufficientFunds) {
      return "modal-donation.flow.insufficient-funds";
    }

    if (
      this.errorCodeIdealPayment === 0 &&
      this.errorCodeStripeCard !== null &&
      this.errorCodeStripeCard !== DeclineCode.ExpiredCard &&
      this.errorCodeStripeCard !== DeclineCode.CardNotSupported &&
      this.errorCodeStripeCard !== DeclineCode.InsufficientFunds
    ) {
      return "modal-donation.flow.payment-error-update";
    }

    return "modal-donation.flow.something-wrong";
  }

  @computed get textButtonError(): string {
    if (this.isPaymentOneFlowError) {
      return this.errorMessageOneFlow.buttonLabel;
    }

    if (ErrorCode.includes(this.errorCodeIdealPayment)) {
      return "modal-donation.flow.buttons.try-again-update";
    }
    if (this.errorCodeStripeCard === DeclineCode.CardNotSupported) {
      return "modal-donation.flow.buttons.new-details";
    }
    if (
      this.errorCodeStripeCard === DeclineCode.ExpiredCard ||
      this.errorCodeStripeCard === DeclineCode.InsufficientFunds
    ) {
      return "modal-donation.flow.buttons.choose-payment";
    }
    if (
      this.errorCodeStripeCard !== null &&
      this.errorCodeStripeCard !== DeclineCode.ExpiredCard &&
      this.errorCodeStripeCard !== DeclineCode.CardNotSupported &&
      this.errorCodeStripeCard !== DeclineCode.InsufficientFunds
    ) {
      return "modal-donation.flow.buttons.try-again-update";
    }
    return "modal-donation.flow.buttons.retry";
  }

  @action preSelectUonOption = (uon: number): void => {
    this.updateUonSize(uon);
    this.preSelectSize = uon;
    this.updateScreen(DonateScreen.SelectAmount);
  };

  @action.bound openModalOneFlowOrDonate(
    initialState?: OneFlowInitialState,
  ): void {
    if (
      this.featureFlaggingStore.flags.enableOneFlow &&
      !this.userSessionStore.user?.isGroupUnpublished
    ) {
      this.modalStore.openModal({ name: "oneFlow", initialState });
    } else {
      this.modalStore.openModal("donate");
    }
  }

  @action.bound prepareCharityDonation = (
    fullName: string,
    sourceId: string,
  ): void => {
    this.campaignSource = null;
    this.charityProfile = { name: fullName, id: sourceId };
    this.screen = DonateScreen.PreSelection;
    this.openModalOneFlowOrDonate();
  };

  @action.bound prepareNpoCampaignDonation = (user: UserModel): void => {
    this.campaignSource = null;

    if (user.isDisabledProtect) {
      this.updateNpoId("");
    } else {
      this.updateNpoId(user.id);
    }

    this.screen = DonateScreen.PreSelection;
    this.openModalOneFlowOrDonate();
  };

  @action.bound prepareCollectableDonation = (
    promotionId: string,
    seriesType?: PromotionSeriesType,
    promotionUonImageUrl?: string,
    profileStore?: IProfileInfo | null,
  ): void => {
    this.promotionId = promotionId;
    this.screen = DonateScreen.PreSelection;
    this.profileStore = profileStore || null;
    this.openModalOneFlowOrDonate({
      oneFlowType: OneFlowType.PROTECT,
      currentScreen: OneFlowScreen.START,
      promotionSeriesType: seriesType,
      promotionUonImageUrl,
    });
  };

  @action.bound prepareCampaignDonation = (
    user: { fullName: string; isDisabledProtect?: boolean },
    campaignId: string,
  ): void => {
    this.campaignSource = user.isDisabledProtect
      ? null
      : { fullName: user.fullName, campaignId };

    this.screen = DonateScreen.PreSelection;
    this.openModalOneFlowOrDonate();
  };

  @action.bound openPreselection = (): void => {
    this.screen = DonateScreen.PreSelection;
    this.isDonateHomeHeaderCard = true;
    this.openModalOneFlowOrDonate();
  };

  @action prepareProtectDonation = (size: UonSize): void => {
    this.charityProfile = null;
    this.campaignSource = null;
    this.npoId = "";
    this.updateUonSize(size);
    this.preSelectSize = size;
    this.openModalOneFlowOrDonate({
      currentScreen: OneFlowScreen.CHOOSE_M2,
      oneFlowType: OneFlowType.PROTECT,
      size: size || 1,
      sizeOption: typeof size === "number" ? size : 1,
      preSelectSize: typeof size === "number" ? size : 1,
    });
  };

  @computed get isOptionSizeDisabled(): boolean {
    return !!this.preSelectSize;
  }

  @computed get isCharityDonate(): boolean {
    return !!this.charityProfile;
  }

  @computed get isDonateToCampaign(): boolean {
    return !!this.campaignSource;
  }

  @action resetOnClickOutSide = (): void => {
    this.screen = DonateScreen.SelectAmount;
    this.customSize = "";
    this.charityProfile = null;
    this.campaignSource = null;
    this.npoId = "";
    this.promotionId = "";
    this.preSelectSize = null;
    this.method = null;
    this.selectMethodScreenError = null;
    this.errorCodeIdealPayment = 0;
    this.errorCodeStripeCard = null;
    this.cardElementComplete = false;
    this.paymentStatus = null;
    this.paymentResponse = null;
    this.errorMollieResponse = null;
    this.isDonateHomeHeaderCard = false;
    this.oneFlowId = "";
    this.isPreparingAccount = false;
    this.isRetryPaymentOneFlow = false;
    this.modalStore.closeLazyModal();
  };

  @action backToPreSelectionScreen = (): void => {
    this.screen = DonateScreen.PreSelection;
  };

  @action backToSelectAmountScreen = (): void => {
    this.screen = DonateScreen.SelectAmount;
    this.selectMethodScreenError = null;
    this.errorCodeIdealPayment = 0;
    this.errorCodeStripeCard = null;
    this.cardElementComplete = false;
  };

  @action updateCampaignSource = (
    fullName?: string,
    campaignId?: string,
  ): void => {
    if (!fullName && !campaignId) {
      return;
    }

    if (!this.campaignSource) {
      this.campaignSource = {
        fullName: fullName || "",
        campaignId: campaignId || "",
      };
      return;
    }

    if (fullName) {
      this.campaignSource.fullName = fullName;
    }

    if (campaignId) {
      this.campaignSource.campaignId = campaignId;
    }
  };

  @action.bound updateCampaignHeaderCard(b: boolean): void {
    this.isCampaignHeaderCard = b;
  }

  @computed get vanityName(): string {
    return this.userSessionStore.user?.vanityName || "";
  }

  @computed get isRefreshRelatedData(): boolean {
    return Router.pathname === "/[vanityName]";
  }

  @computed get groupId(): string {
    return this.profileStore?.groupId || "";
  }

  removeQueryRarams = (url: string): string => {
    const [originalUrl, queryString] = url.split("?");

    if (!queryString) {
      return url;
    }

    const params = new URLSearchParams(queryString);

    // delete pre-select uon modal params
    params.delete("donate_campaign_id");
    params.delete("donate_amount");

    // delete oneFlowType and protectSize query params
    params.delete("oneFlowType");
    params.delete("protectSize");

    const sanitizeParams = params.toString() ? `?${params.toString()}` : "";
    const newUrl = originalUrl + sanitizeParams;

    return newUrl;
  };

  generateDeepLink = (campaignId: string, uonSize: number): void => {
    if (this.isDonateHomeHeaderCard) return;
    if (
      (Router.pathname === `/[vanityName]` && !this.isCampaignHeaderCard) ||
      Router.pathname === `/[vanityName]/campaigns/open/[linkNameCampaign]`
    ) {
      return;
    }

    history.pushState(
      {},
      "",
      `?donate_campaign_id=${campaignId}&donate_amount=${uonSize}`,
    );
  };

  @computed get isUseArrowIcon(): boolean {
    return (
      this.hasPrevSelectionWindow &&
      this.screen !== DonateScreen.PreSelection &&
      this.screen !== DonateScreen.Success
    );
  }

  @computed get sourcePayload(): SourcePayload {
    if (this.campaignSource) {
      return {
        sourceId: this.campaignSource.campaignId,
        sourceType: SourceType.CAMPAIGN,
        campaignName: this.campaignSource.fullName,
      };
    }
    if (this.charityProfile) {
      return {
        sourceId: this.charityProfile.id,
        sourceType: SourceType.CHARITY,
        charityName: this.charityProfile.name,
      };
    }
    if (this.npoId !== "") {
      return { sourceId: this.npoId, sourceType: SourceType.NPO };
    }
    if (this.reserveId !== "") {
      return { sourceId: this.reserveId, sourceType: SourceType.RESERVE };
    }
    return { sourceId: undefined, sourceType: SourceType.PROTECT };
  }

  @computed.struct private get donateToLocal(): {
    key: string;
    var?: { name: string };
  } {
    if (this.sourcePayload.sourceType === SourceType.CHARITY) {
      return {
        key: "modal-donation.cost.donation-to",
        var: { name: this.sourcePayload.charityName },
      };
    }

    return { key: "modal-donation.cost.donation-to-foundation" };
  }

  @computed get donateTo(): { key: string; var?: { name: string } } {
    return this.donateToLocal;
  }

  @action.bound prepareAccountOneFlow = flow(function* prepareAccountOneFlow(
    this: DonateStore,
    data: {
      type: OneFlowType;
      actAs: OneFlowActAs;
      signupEmail?: UserRegisterPayload;
      newGroup?: NewGroupPayload;
      existingGroupId?: string;
      sessionSource?: OneFlowSessionSource;
    },
    groupImage: File | null,
  ) {
    try {
      this.isPreparingAccount = true;
      const { oneFlowId, userResponse, groupResponse } =
        yield this.paymentAPI.prepareAccountOneFlow(data);

      this.oneFlowId = oneFlowId;

      if (!this.userSessionStore.user && !userResponse?.emailAddressVerified) {
        this.theMessageStore.showMessage(
          {
            typeMessage: "Close",
            title: "toast-message.email-verification.title",
            content: "toast-message.email-verification.content-update",
          },
          {
            closeDuration: "never",
          },
        );
      }
      if (groupResponse) {
        this.userSessionStore.setUser(groupResponse);
        this.groupStore.groupImage = groupImage;
        yield this.groupStore.uploadGroupImage();
      } else {
        yield this.userSessionStore.setUser(userResponse);
      }
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    } finally {
      this.isPreparingAccount = false;
    }
  });

  @action.bound handleStripePaymentFinalization = flow(
    function* handlePaymentFinalization(this: DonateStore) {
      const finalizeResponse = yield* toFlowGeneratorFunction(
        this.paymentAPI.getPaymentResponseStripeOneFlow,
      )(this.oneFlowId);
      this.oneFlowDonationTypeFromResponse = finalizeResponse.type;
      this.oneFlowShareId =
        finalizeResponse.type === PaymentFinalizeResponseType.PROTECT ||
        finalizeResponse.type === PaymentFinalizeResponseType.SUBSCRIBE_GIFT
          ? finalizeResponse.shareId || ""
          : "";

      this.paymentStatus =
        finalizeResponse.type === PaymentFinalizeResponseType.ERROR
          ? null
          : finalizeResponse.paymentResponse.status;

      // handle case error
      if (finalizeResponse.type === PaymentFinalizeResponseType.ERROR) {
        this.errorCodeStripeCard = translateAPIError(finalizeResponse);
        return;
      }

      // handle case SUBSCRIBE_PERSONAL
      if (
        finalizeResponse.type ===
          PaymentFinalizeResponseType.SUBSCRIBE_PERSONAL &&
        finalizeResponse.redeemResponse &&
        (finalizeResponse.redeemResponse as any).deckDetails
      ) {
        this.protectedByPersonalSubscription = (
          finalizeResponse.redeemResponse as any
        ).deckDetails.path[0];
      }

      // handle case PROTECT
      if (
        finalizeResponse?.paymentResponse?.transaction?.imageSource ===
          ImageSource.RESERVE_IMAGE &&
        finalizeResponse?.paymentResponse?.transaction?.donatedReserve
          ?.deckDetails?.path &&
        this.oneFlowDonationTypeFromResponse ===
          PaymentFinalizeResponseType.PROTECT
      ) {
        const reserveResponse = yield this.reserveAPI.fetchReserve(
          finalizeResponse.paymentResponse?.transaction?.donatedReserve
            .deckDetails.path[1] || "",
        );
        this.reserve = reserveResponse;
      }

      // handle case SUBSCRIBE_GIFT
      if (
        finalizeResponse.type === PaymentFinalizeResponseType.SUBSCRIBE_GIFT
      ) {
        snowplowCaptureCodeIssuedEvent({
          codeType: "gift_subscription",
          issuer: this.vanityName,
          issueSource: "oneflow_create_gift_subscription",
          uonValue: finalizeResponse.paymentResponse.numberOfUon,
        });
      }

      this.paymentResponse = finalizeResponse.paymentResponse;
      this.screen = DonateScreen.Success;
      this.subscribeOrPushProtectedEvent();
    },
  );

  @action.bound submitStripeCardFormOneFlow = flow(
    function* submitStripeCardFormOneFlow(
      this: DonateStore,
      stripe: Stripe,
      elements: StripeElements,
      data: {
        type: OneFlowType;
        giftsCount?: number;
      },
    ) {
      const isGiftSubscription = !!data.giftsCount;
      const prepareStripePaymentPayload =
        this.buildPreparePaymentPayloadOneFlow(
          data.type,
          this.sourcePayload.sourceId,
          this.sourcePayload.sourceType,
          data.giftsCount,
        );

      if (this.isRetryPaymentOneFlow) {
        yield this.retryStripeCardFormOneFlow(
          stripe,
          elements,
          {
            payment: prepareStripePaymentPayload,
          },
          isGiftSubscription,
        );
        return;
      }

      this.errorCodeStripeCard = null;
      this.stripeCardSubmitting = true;
      this.selectMethodScreenError = null;
      try {
        const { oneFlowId, paymentResponse, type } =
          yield* toFlowGeneratorFunction(this.paymentAPI.preparePaymentOneFlow)(
            this.oneFlowId,
            {
              giftsCount: data.giftsCount,
              payment: prepareStripePaymentPayload,
            },
          );
        this.payment = paymentResponse;
        this.oneFlowId = oneFlowId;
      } catch (error) {
        this.stripeCardSubmitting = false;
        this.selectMethodScreenError = translateAPIError(error);
      }

      if (this.payment?.provider === PaymentProvider.MOLLIE) {
        yield this.retryStripeCardFormOneFlow(
          stripe,
          elements,
          {
            payment: prepareStripePaymentPayload,
          },
          isGiftSubscription,
        );
        return;
      }

      const { paymentKey } = this;

      if (!paymentKey) {
        return;
      }
      try {
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(
          CardElement,
        ) as StripeCardElement;
        const result = yield stripe.confirmCardPayment(paymentKey, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: this.userFullName,
            },
          },
        });
        if (result.error) {
          if (result.error.code === "card_declined") {
            this.errorCodeStripeCard = result.error.decline_code;
            return;
          }
          this.errorCodeStripeCard = result.error.code;
          return;
        }

        yield this.handleStripePaymentFinalization();
      } catch (error) {
        this.errorCodeStripeCard = translateAPIError(error);
      } finally {
        this.stripeCardSubmitting = false;
        this.isJustDonated = true;
      }
    },
  );

  @action.bound submitIdealPaymentOneFlow = flow(
    function* submitIdealPaymentOneFlow(
      this: DonateStore,
      data: {
        type: OneFlowType;
        actAs: OneFlowActAs;
        giftsCount?: number;
        signupEmail?: UserRegisterPayload;
        newGroup?: NewGroupPayload;
        existingGroupId?: string;
        sessionSource?: OneFlowSessionSource;
        redirectionConfig?: OneFlowRedirectionConfig;
      },
      groupImage: File | null,
    ) {
      const prepareMolliePaymentPayload =
        this.buildPreparePaymentPayloadOneFlow(
          data.type,
          this.sourcePayload.sourceId,
          this.sourcePayload.sourceType,
          data.giftsCount,
          data.redirectionConfig,
        );

      if (this.isRetryPaymentOneFlow) {
        yield this.retryIdealPaymentOneFlow({
          payment: prepareMolliePaymentPayload,
        });
        return;
      }

      this.selectMethodScreenError = null;
      this.selectFormSubmitting = true;
      this.idealCardSubmitting = true;

      try {
        const { oneFlowId, paymentResponse, type } =
          yield* toFlowGeneratorFunction(this.paymentAPI.preparePaymentOneFlow)(
            this.oneFlowId,
            {
              giftsCount: data.giftsCount,
              payment: prepareMolliePaymentPayload,
            },
          );

        this.payment = paymentResponse;
        this.oneFlowId = oneFlowId;

        if (this.payment?.checkoutUrl) {
          window.location.href = this.payment.checkoutUrl;
          // reset payment after redirect
          this.payment = null;
          return;
        }

        this.screen = DonateScreen.Success;
      } catch (error: any) {
        this.selectMethodScreenError = translateAPIError(error);
        this.errorCodeIdealPayment = (error as any).response.status;
        this.screen = DonateScreen.PaymentDetails;
      } finally {
        this.selectFormSubmitting = false;
        this.idealCardSubmitting = false;
      }
    },
  );

  @action.bound fetchMolliePaymentStatus = flow(
    function* fetchMolliePaymentStatus(
      this: DonateStore,
      finalizeData: PaymentContext,
      oneFlowId?: string,
      oneFlowType?: OneFlowType,
      redirectPath?: string,
      redirectMode?: OneFlowRedirectMode,
    ) {
      this.successScreenLoading = true;

      const initialState: OneFlowInitialState = {
        currentScreen: OneFlowScreen.PAYMENT,
        oneFlowType: oneFlowType || OneFlowType.SUBSCRIBE,

        redirectionConfig: {
          creditedEntity: {
            type: "GLOBAL",
          },
          redirectPath: redirectPath || "",
          redirectMode: redirectMode ?? "onPaymentSuccessAndClose",
        },
      };

      oneFlowId
        ? yield this.fetchPaymentStatusOneFlow(
            {
              ...finalizeData,
              oneFlowId,
            },
            initialState,
          )
        : yield this.fetchPaymentStatus(finalizeData);
    },
  );

  @action.bound fetchPaymentStatusOneFlow = flow(
    function* fetchPaymentStatusOneFlow(
      this: DonateStore,
      context: PaymentContextOneFlow,
      initialState: OneFlowInitialState,
    ) {
      this.errorCodeIdealPayment = 0;
      const donatedNumber = context.numberOfUon || 0;
      if (uonSizeOptions.includes(donatedNumber)) {
        this.size = donatedNumber;
      } else {
        this.size = "custom";
        this.customSize = donatedNumber.toString();
      }
      try {
        this.successScreenLoading = true;
        this.screen = DonateScreen.Success;

        if (context.sourceType === SourceType.NPO) {
          this.updateNpoId(context.sourceId || "");
        }

        const finalizeResponse = yield* toFlowGeneratorFunction(
          this.paymentAPI.getPaymentResponseMollieOneFlow,
        )(context.oneFlowId || "", context.token);

        this.oneFlowId = finalizeResponse.oneFlowId;
        this.oneFlowDonationTypeFromResponse = finalizeResponse.type;
        this.oneFlowShareId =
          finalizeResponse.type === PaymentFinalizeResponseType.PROTECT ||
          finalizeResponse.type === PaymentFinalizeResponseType.SUBSCRIBE_GIFT
            ? finalizeResponse.shareId || ""
            : "";

        // handle case SUBSCRIBE_PERSONAL
        if (
          finalizeResponse.type ===
            PaymentFinalizeResponseType.SUBSCRIBE_PERSONAL &&
          finalizeResponse.redeemResponse &&
          (finalizeResponse.redeemResponse as any).deckDetails
        ) {
          this.protectedByPersonalSubscription = (
            finalizeResponse.redeemResponse as any
          ).deckDetails.path[0];
        }

        // handle case error
        if (finalizeResponse.type === PaymentFinalizeResponseType.ERROR) {
          this.errorMollieResponse = finalizeResponse.errorResponse;
          this.screen = DonateScreen.PaymentDetails;
          this.modalStore.openModal({ name: "oneFlow", initialState });
          return;
        }

        if (
          !!this.paymentStatus &&
          this.paymentStatus !== PaymentStatus.DONATED &&
          this.paymentStatus !== PaymentStatus.UON_QUEUED &&
          this.paymentStatus !== PaymentStatus.SUCCEEDED &&
          this.paymentStatus !== PaymentStatus.COLLECTED &&
          this.paymentStatus !== PaymentStatus.CODE_ENQUEUED
        ) {
          this.screen = DonateScreen.PaymentDetails;
        }

        // handle case PROTECT
        if (
          finalizeResponse?.paymentResponse?.transaction?.imageSource ===
            ImageSource.RESERVE_IMAGE &&
          finalizeResponse?.paymentResponse?.transaction?.donatedReserve
            ?.deckDetails?.path &&
          finalizeResponse.paymentResponse?.transaction?.donatedReserve
            ?.deckDetails.path[1] &&
          this.oneFlowDonationTypeFromResponse === OneFlowDonationType.PROTECT
        ) {
          const reserveResponse = yield this.reserveAPI.fetchReserve(
            finalizeResponse.paymentResponse?.transaction?.donatedReserve
              .deckDetails.path[1] || "",
          );
          this.reserve = reserveResponse;
        }

        this.paymentResponse = finalizeResponse.paymentResponse;
        this.paymentStatus = finalizeResponse.paymentResponse.status;
        this.subscribeOrPushProtectedEvent();
        this.modalStore.openModal({ name: "oneFlow", initialState });

        // handle case SUBSCRIBE_GIFT
        if (finalizeResponse.type === OneFlowDonationType.SUBSCRIBE_GIFT) {
          snowplowCaptureCodeIssuedEvent({
            codeType: "gift_subscription",
            issuer: this.vanityName,
            issueSource: "oneflow_create_gift_subscription",
            uonValue: finalizeResponse.paymentResponse.numberOfUon,
          });
        }
      } catch (error) {
        if (isOneFlowFinalizePartialSuccessResponse(error)) {
          this.oneFlowId = error.body.oneFlowId;

          this.errorMollieResponse = error.body.errorResponse;
          this.screen = DonateScreen.PaymentDetails;
          this.modalStore.openModal({ name: "oneFlow", initialState });
          return;
        }

        this.errorCodeIdealPayment = (error as any).response?.status;
        this.screen = DonateScreen.PaymentDetails;
      } finally {
        this.successScreenLoading = false;
        this.isJustDonated = true;
      }
    },
  );

  @action.bound fetchPaymentMethods = flow(function* fetchPaymentMethods(
    this: DonateStore,
  ) {
    try {
      const res: PaymentMethodResponse[] =
        yield this.paymentAPI.getPaymentMethods();
      this.paymentMethods.replace(resolvePaymentMethods(res));

      if (
        this.featureFlaggingStore.flags.enableMultiCurrency &&
        this.currency === Currency.USD
      ) {
        this.paymentMethods.replace(
          this.paymentMethods.filter(
            (method) => method !== PaymentMethod.IDeal,
          ),
        );
      }
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    }
  });

  @action.bound retryIdealPaymentOneFlow = flow(
    function* retryIdealPaymentOneFlow(
      this: DonateStore,
      data: OneFlowPaymentRetryPayload,
    ) {
      try {
        this.resetErrorPayment();
        this.selectFormSubmitting = true;

        const { oneFlowId, paymentResponse, type } =
          yield this.paymentAPI.retryPreparePaymentOneFlow(
            this.oneFlowId,
            data,
          );
        this.payment = paymentResponse;
        this.oneFlowId = oneFlowId;
        this.oneFlowDonationTypeFromResponse = type;

        if (this.payment?.checkoutUrl) {
          window.location.href = this.payment.checkoutUrl;
          // reset payment after redirect
          this.payment = null;
          return;
        }

        this.screen = DonateScreen.Success;
      } catch (error) {
        this.selectMethodScreenError = translateAPIError(error);
        this.errorCodeIdealPayment = (error as any).response.status;
        this.screen = DonateScreen.PaymentDetails;
      } finally {
        this.selectFormSubmitting = false;
        this.isRetryPaymentOneFlow = false;
      }
    },
  );

  @action.bound retryStripeCardFormOneFlow = flow(
    function* retryStripeCardFormOneFlow(
      this: DonateStore,
      stripe: Stripe,
      elements: StripeElements,
      data: OneFlowPaymentRetryPayload,
      isGiftSubscscription: boolean,
    ) {
      try {
        this.resetErrorPayment();
        this.stripeCardSubmitting = true;

        const { oneFlowId, paymentResponse, type } =
          yield this.paymentAPI.retryPreparePaymentOneFlow(
            this.oneFlowId,
            data,
          );
        this.payment = paymentResponse;
        this.oneFlowId = oneFlowId;
        this.oneFlowDonationTypeFromResponse = type;

        // capture gift subscribe issue event
        if (isGiftSubscscription) {
          snowplowCaptureCodeIssuedEvent({
            codeType: "gift_subscription",
            issuer: this.vanityName,
            issueSource: "oneflow_create_gift_subscription",
            uonValue: paymentResponse.numberOfUon,
          });
        }
      } catch (error) {
        this.selectMethodScreenError = translateAPIError(error);
      }

      const { paymentKey } = this;

      if (!paymentKey) {
        return;
      }
      try {
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(
          CardElement,
        ) as StripeCardElement;
        const result = yield stripe.confirmCardPayment(paymentKey, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: this.userFullName,
            },
          },
        });
        if (result.error) {
          if (result.error.code === "card_declined") {
            this.errorCodeStripeCard = result.error.decline_code;
            return;
          }
          this.errorCodeStripeCard = result.error.code;
          return;
        }

        yield this.handleStripePaymentFinalization();
      } catch (error) {
        this.errorCodeStripeCard = translateAPIError(error);
      } finally {
        this.stripeCardSubmitting = false;
        this.isJustDonated = true;
        this.isRetryPaymentOneFlow = false;
      }
    },
  );

  @action.bound resetErrorPayment(): void {
    this.errorCodeIdealPayment = 0;
    this.errorCodeStripeCard = null;
    this.selectMethodScreenError = null;
  }

  @action.bound snowplowCaptureUonProtectedEvent(): void {
    if (
      !this.responseDonateSuccess ||
      this.oneFlowDonationTypeFromResponse !== OneFlowDonationType.PROTECT
    )
      return;
    const data = {
      uonValue: this.responseDonateSuccess.uonReserveSize,
      paymentMethod:
        this.method === PaymentMethod.Card ? "stripe_credit_card" : "ideal",
      wallet: this.userSessionStore.user?.id || "",
      protectedBy: this.responseDonateSuccess.vanityNameProtectedBy,
      sponsoredBy: this.responseDonateSuccess.vanityNameDonatedBy,
      registeredTo: this.responseDonateSuccess.vanityNameRegisteredTo,
    };
    snowplowCaptureUonProtectedEvent(data);
  }

  @action.bound updateTriggerPointDataCy(dataCy: string): void {
    this.triggerPointDataCy = dataCy;
  }

  @computed get isQueue(): boolean {
    return (
      this.paymentStatus === PaymentStatus.UON_QUEUED ||
      this.paymentStatus === PaymentStatus.SUCCEEDED ||
      this.paymentStatus === PaymentStatus.CODE_ENQUEUED
    );
  }

  @action.bound subscribeOrPushProtectedEvent(): void {
    if (this.isQueue) {
      this.paymentAPI.subscribeOnceUonCardCreated(
        this.userSessionStore.user?.id || "",
      );
      return;
    }
    this.snowplowCaptureUonProtectedEvent();
  }

  @action.bound onModalDonationScreenSuccessGiftButtonClick = flow(function* (
    this: DonateStore,
  ) {
    if (!this.responseDonateSuccess) {
      return;
    }

    yield* toFlowGeneratorFunction(
      this.responseDonateSuccess.openGivingModal,
    )();
  });

  @computed get paymentRegion(): PaymentRegion {
    return this.userSessionStore.paymentRegion;
  }

  @computed get currency(): Currency {
    if (this.featureFlaggingStore.flags.enableMultiCurrency) {
      return this.paymentRegion === PaymentRegion.EU
        ? Currency.EUR
        : Currency.USD;
    }

    return Currency.EUR;
  }

  @computed get priceWithVAT(): number {
    if (this.size === "custom") {
      return Number(this.customSize) * priceForVAT;
    }

    return this.size * priceForVAT;
  }

  @computed get donationPrice(): number {
    return this.size === "custom" ? Number(this.customSize) : this.size;
  }
}
