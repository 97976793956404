import {
  ImageSource,
  UonStandard,
  UonTransactionType,
} from "@earthtoday/contract";
/* eslint-disable unicorn/prefer-spread */
import { observer } from "mobx-react-lite";
import Link from "next/link";

import star from "../../assets/svgs/star.svg";
import UonM2Logo from "../../assets/svgs/uon-m2-logo.svg";
import { LIST_PREFIX_KEYS } from "../../shared/constants";
import {
  Direction,
  transformDigitToDegrees,
} from "../../shared/helpers/coordinates";
import { LatLng } from "../../shared/models/Uon";
import { GroupProfileType, UserType } from "../../shared/models/User";
import { useTranslation } from "../../shared/translate/NextI18next";
import { LazyImage } from "../LazyImage/LazyImage";
import { CardItemGift, CardItemGiftDriver } from "./CardItemGift";
import sc from "./CardItemTransactionStandard.styled";
import { formatUonNumber } from "./helper/formatUonNumber";

export enum UonCardLocation {
  DONATION_SUCCESS = "DONATION_SUCCESS",
  WALLET = "WALLET",
  CERTIFICATE = "CERTIFICATE",
}

export type CardItemTransactionStandardDriver = {
  location: UonCardLocation;
  imageSource: typeof ImageSource.RESERVE_IMAGE;
  card?: UonStandard;
  id: string;
  reserveName: string;
  reserveDeckUrl: string;
  designedBy: string;
  designedByUrl: string;
  onDesignedByClicked(): void;
  protectedBy: string;
  protectedByUrl: string;
  onProtectedByClicked(): void;
  sponsoredBy: string;
  sponsoredByUrl: string;
  onSponsoredByClicked(): void;
  registeredTo: string;
  registeredToUrl: string;
  onRegisteredToClicked(): void;
  reserveImageUrl: string;
  uonReserveSize: number;
  point: LatLng | null;
  userType: UserType;
  numberOfUons: string;
  isEndWithOneUonCard: boolean;
  topRightNote: string;
  issuedAt: string;
  isMobileDevice: boolean;
  isGiftButtonVisible: boolean;
  type: UonTransactionType;
  isSubmiting: boolean;
  isEndWithOne: boolean;
  groupRole?: GroupProfileType;

  isGiveButtonEnable: boolean;
  openGivingModal(options?: { fadeAnimaion?: boolean }): void;
  onGiveBtnClicked(): void;
  onVistButtonClicked(): void;
  shouldOpenWithBlank?: boolean; // use on certificate
};

interface IProps {
  driver: CardItemTransactionStandardDriver;
  cardItemGiftDriver?: CardItemGiftDriver; // dont need this in certificate
  isDisableVisitM2?: boolean;
}

export const CardItemTransactionStandard = observer(function CardTransaction(
  props: IProps,
) {
  const { t } = useTranslation("CardItemTransaction");
  const {
    onDesignedByClicked,
    onProtectedByClicked,
    onRegisteredToClicked,
    onSponsoredByClicked,
  } = props.driver;

  const renderCardDonator = () => {
    return (
      <sc.CardDonator>
        <sc.CardDonatorHeader data-cy="CardTransaction-DesignedBy">
          {t("standard.designed-by")}
          <Link
            href="/[vanityName]"
            as={`/${props.driver.designedByUrl}`}
            target={props.driver.shouldOpenWithBlank ? "_black" : "_self"}
            passHref
            legacyBehavior
          >
            <sc.UonInfoLink
              onClick={(e) => {
                e.preventDefault();
                onDesignedByClicked();
              }}
            >
              {props.driver.designedBy}
            </sc.UonInfoLink>
          </Link>
        </sc.CardDonatorHeader>
        <sc.CardDonatorHeader data-cy="CardTransaction-NPO">
          {t("standard.protected-by")}
          <Link
            href="/[vanityName]"
            as={`/${props.driver.protectedByUrl}`}
            target={props.driver.shouldOpenWithBlank ? "_black" : "_self"}
            passHref
            legacyBehavior
          >
            <sc.UonInfoLink
              onClick={(e) => {
                e.preventDefault();
                onProtectedByClicked();
              }}
            >
              {props.driver.protectedBy}
            </sc.UonInfoLink>
          </Link>
        </sc.CardDonatorHeader>
        <sc.CardDonatorHeader data-cy="CardTransaction-DonatorName">
          {t("standard.sponsored-by")}
          <Link
            href="/[vanityName]"
            as={`/${props.driver.sponsoredByUrl}`}
            target={props.driver.shouldOpenWithBlank ? "_black" : "_self"}
            passHref
            legacyBehavior
          >
            <sc.UonInfoLink
              onClick={(e) => {
                e.preventDefault();
                onSponsoredByClicked();
              }}
            >
              {props.driver.sponsoredBy}
            </sc.UonInfoLink>
          </Link>
        </sc.CardDonatorHeader>
        <sc.CardDonatorHeader data-cy="CardTransaction-RegisteredName">
          {t("standard.registered-to")}
          <Link
            href="/[vanityName]"
            as={`/${props.driver.registeredToUrl}`}
            target={props.driver.shouldOpenWithBlank ? "_black" : "_self"}
            passHref
            legacyBehavior
          >
            <sc.UonInfoLink
              onClick={(e) => {
                e.preventDefault();
                onRegisteredToClicked();
              }}
            >
              {props.driver.registeredTo}
            </sc.UonInfoLink>
          </Link>
        </sc.CardDonatorHeader>
      </sc.CardDonator>
    );
  };
  const renderVisitProtected = (canVisit = true) => {
    if (props.cardItemGiftDriver?.shouldRenderGiftItem) {
      return null;
    }
    return (
      <>
        {canVisit &&
          (props.driver.isMobileDevice ? (
            <Link
              href="/m2/[transactionId]"
              as={`/m2/${props.driver.id}`}
              passHref
              legacyBehavior
            >
              <sc.VisitM2Mobile
                data-cy="CardTransaction-VisitProtectedReserveLinkMobile"
                uonCount={props.driver.uonReserveSize}
                pointerEvents={props.isDisableVisitM2 ? "none" : "auto"}
                target={props.driver.shouldOpenWithBlank ? "_blank" : "_self"}
                onClick={props.driver.onVistButtonClicked}
              >
                {t("standard.button.visit")}
              </sc.VisitM2Mobile>
            </Link>
          ) : (
            <Link
              href="/m2/[transactionId]"
              as={`/m2/${props.driver.id}`}
              passHref
              legacyBehavior
            >
              <sc.VisitM2
                data-cy="CardTransaction-VisitProtectedReserveLink"
                uonCount={props.driver.uonReserveSize}
                pointerEvents={props.isDisableVisitM2 ? "none" : "auto"}
                target={props.driver.shouldOpenWithBlank ? "_blank" : "_self"}
                onClick={props.driver.onVistButtonClicked}
              >
                {t("standard.button.visit")}
              </sc.VisitM2>
            </Link>
          ))}
        {props.driver.isGiftButtonVisible ? (
          <sc.GiveM2
            disabled={!props.driver.isGiveButtonEnable}
            data-cy="CardTransaction-GiveBtn"
            uonCount={props.driver.uonReserveSize}
            pointerEvents={props.isDisableVisitM2 ? "none" : "auto"}
            onClick={() => props.driver.openGivingModal()}
          >
            {t("standard.button.give")}
          </sc.GiveM2>
        ) : null}
      </>
    );
  };

  const renderCardItemTransaction = () => {
    return (
      <sc.Container>
        {props.cardItemGiftDriver?.shouldRenderGiftItem && (
          <CardItemGift driver={props.cardItemGiftDriver} />
        )}

        <sc.EarthLines uonCount={props.driver.uonReserveSize} />
        <sc.TopOverLay uonCount={props.driver.uonReserveSize} />
        <sc.MiddleOverLay2 uonCount={props.driver.uonReserveSize} />
        <sc.Square uonCount={props.driver.uonReserveSize} />
        <sc.CardContentContainer>
          <sc.UonReserveContainer data-cy="CardTransaction-UonReserveContainer">
            <sc.UonReserveBackground
              data-cy="CardTransaction-UonReserveBackground"
              backgroundImage={props.driver.reserveImageUrl || ""}
            >
              <sc.SquareM2>
                <LazyImage
                  src={UonM2Logo}
                  width="280px"
                  maxWidth="280px"
                  height="280px"
                  maxHeight="280px"
                  alt="uon m2 logo"
                />
                <sc.UonReserveTop>
                  <sc.UonReserveCount
                    data-cy="CardTransaction-UonReserveCount"
                    isEndWithOne={props.driver.isEndWithOneUonCard}
                    uonCount={props.driver.uonReserveSize}
                  >
                    {formatUonNumber(props.driver.uonReserveSize)}
                  </sc.UonReserveCount>
                  <sc.UonReserveTitle data-cy="CardTransaction-UonReserveTitle">
                    <sc.UonReserveTitle1>
                      {t("standard.uon")}
                    </sc.UonReserveTitle1>
                    {t("standard.unit-of-nature")}
                  </sc.UonReserveTitle>
                </sc.UonReserveTop>
                <sc.TopRightStar src={star} />
                <sc.TopRightText>
                  {props.driver.point && (
                    <>
                      <sc.Coordinate data-cy="CardTransaction-Coordinate">
                        {`${transformDigitToDegrees(
                          props.driver.point.lat,
                          Direction.Latitude,
                        )} 
                       -
                        ${transformDigitToDegrees(
                          props.driver.point.lng,
                          Direction.Longitude,
                        )}`}
                      </sc.Coordinate>
                    </>
                  )}
                </sc.TopRightText>
                <sc.IssuedAt>{props.driver.issuedAt}</sc.IssuedAt>
              </sc.SquareM2>
            </sc.UonReserveBackground>
          </sc.UonReserveContainer>
          <sc.CardContent data-cy="CardTransaction-CardContent">
            <sc.CardTitle data-cy="CardTransaction-ProtectedReserveName">
              {props.driver.reserveName}
            </sc.CardTitle>
            {renderCardDonator()}
          </sc.CardContent>
        </sc.CardContentContainer>
        {props.driver.type !== UonTransactionType.GIFT_CODE &&
          props.driver.location === UonCardLocation.WALLET && (
            <sc.CardFooter>
              {props.driver.userType.toLowerCase() ===
                UserType.BRAND.toLowerCase() ||
              props.driver.groupRole?.toLowerCase() ===
                UserType.BRAND.toLowerCase()
                ? renderVisitProtected()
                : renderVisitProtected(
                    props.driver.card?.type?.toLowerCase() !==
                      UonTransactionType.PRE_ALLOCATION.toLowerCase(),
                  )}
            </sc.CardFooter>
          )}
        {(props.driver.location === UonCardLocation.DONATION_SUCCESS ||
          props.driver.location === UonCardLocation.CERTIFICATE) && (
          <sc.CardFooter centered={true}>
            <Link
              href="/m2/[transactionId]"
              as={`/m2/${props.driver.id}`}
              passHref
              legacyBehavior
            >
              <sc.VisitYourM2
                data-cy="CardTransaction-VisitYourM2"
                uonCount={props.driver.uonReserveSize}
                pointerEvents="auto"
                onClick={props.driver.onVistButtonClicked}
              >
                {t("standard.button.visit-your-m2")}
              </sc.VisitYourM2>
            </Link>
          </sc.CardFooter>
        )}
      </sc.Container>
    );
  };
  return (
    <sc.Wrapper
      id={LIST_PREFIX_KEYS.uonCard + props.driver.id}
      className="visual-reg"
      data-cy="CardTransaction-Wrapper"
    >
      {renderCardItemTransaction()}
    </sc.Wrapper>
  );
});
