import styled from "styled-components";

import etLogo from "../../assets/svgs/et-com-logo.svg";
import { etBlack, etBrightLightBlue, etWhite } from "../../shared/colors";
import scUonCount from "../UonCountNumberAnimation/UonCountNumberAnimation.styled";
import close from "./assets/close.png";

namespace sc {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    padding: 24px 16px;
    background-color: ${etBlack};

    width: 100vw;
    min-height: 100vh;
  `;

  export const StartProtectingCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    border-radius: 8px;
    overflow: hidden;

    height: 496px;
    width: 328px;

    border: 1px solid ${etWhite};
  `;
  export const BackgroundContainer = styled.div`
    height: 496px;
    width: 100%;
    position: absolute;
  `;

  export const TheEarth = styled.div`
    position: absolute;
    left: 50%;
    top: 95px;
    transform: translateX(-50%);

    background-image: url(${require("../../assets/img/the-earth-mobile.png")});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    width: 100%;
    height: 100%;
    max-width: 294px;
    max-height: 294px;

    z-index: 2;
  `;

  export const OneFlowSpace = styled.div`
    background-image: url(${require("../../assets/img/space-1-column.png")});
    background-size: cover;
    height: 100%;
    width: 100%;

    position: absolute;
    z-index: 1;
  `;

  export const Gradient = styled.div`
    background: linear-gradient(-180deg, rgba(0, 0, 0, 0) 9%, rgb(0, 0, 0) 96%);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
      0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    opacity: 0.9;

    height: 496px;
    width: 100%;

    position: absolute;
    z-index: 3;
  `;

  export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column;

    position: relative;
    z-index: 10;

    height: 400px;
    width: 100%;
  `;

  export const TopIcon = styled.div``;

  export const FlagPoleIcon = styled.div`
    height: 93px;
    width: 69px;

    position: absolute;
    top: -2px;
    left: calc(50% + 25px);
    transform: translateX(-50%);
  `;

  export const SquareMeterIcon = styled.div`
    background: url(${require("../../assets/img/m2-white.png")}) center center
      no-repeat;
    background-size: contain;

    width: 42px;
    height: 42px;

    margin-top: 78px;
  `;

  export const Text = styled.p`
    margin: 0;
    color: ${etWhite};
    font-family: Roboto;
    font-size: 28px;

    letter-spacing: 0px;
    line-height: 1;
  `;

  export const Title = styled.div`
    display: flex;
    justify-content: center;

    overflow: hidden;

    max-height: 36px;
    min-height: 36px;
    margin-top: 16px;
  `;

  export const Description = styled.span`
    display: inline-block;

    color: ${etWhite};
    font-family: Roboto Condensed;
    text-align: center;
    text-transform: uppercase;

    font-weight: normal;
    letter-spacing: 0px;
    font-size: 18px;
    line-height: 20px;
    max-width: 214px;
    margin-top: 16px;
  `;

  export const CounterWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    min-width: 256px;
    min-height: 32px;
    margin-left: 0;
    margin-top: 8px;

    > div {
      min-height: 32px;
      max-height: 32px;
      min-width: 32px;
      max-width: 32px;
    }

    ${scUonCount.Chain} {
      left: 22.5px;
      top: 12px;

      &:before {
        right: 18px;
      }
    }

    .flap {
      width: 24px !important;
      height: 24px !important;
      margin-top: 2px;

      [data-kind="digit"] {
        font-size: 21px !important;
        line-height: 25px !important;
      }

      > div {
        margin-top: -2px;
      }

      > div:nth-child(2),
      > div:nth-child(4) {
        margin-top: 1px !important;
        margin-bottom: 2px !important;
      }
    }
  `;

  export const VisitUonEarthButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;
    font-size: 16px;
    font-family: Roboto Condensed;
    text-transform: uppercase;
    color: ${etWhite};
    background-color: ${etBrightLightBlue};
    border: none;
    border-radius: 24px;

    width: 186px;
    height: 40px;
    margin-top: auto;
    cursor: pointer;

    &:hover {
      color: ${etWhite};
      text-decoration: none;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
        0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    }
  `;

  export const BecomePartner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${etWhite};
    white-space: pre-line;
    margin-top: 14px;
  `;
  export const BecomePartnerTitle = styled.h2`
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    height: 40px;
    margin-bottom: 0;
  `;
  export const BecomePartnerDescription = styled.p`
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  `;

  export const Footer = styled.div`
    width: 100%;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `;
  export const Logo = styled.div`
    height: 40px;
    width: 40px;
    background: url(${etLogo}) center center no-repeat;
    background-size: contain;
    width: 100%;
  `;
  export const LogoText = styled.div`
    color: ${etWhite};
    margin-top: 7px;
    font-size: 16px;
    font-family: Roboto;
    line-height: 28px;
    height: 28px;
    text-align: center;
  `;

  export const Line = styled.hr`
    width: 100%;
    border-color: white;
    margin: 16px 0;
  `;
  export const Policies = styled.div`
    color: ${etWhite};

    > a {
      color: ${etWhite};

      :hover,
      :active {
        color: ${etWhite};
        text-decoration: none;
      }
    }
  `;
  export const PoliciesOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: auto;
  `;
  export const ClosePoliciesButton = styled.button`
    position: fixed;
    top: 12px;
    right: 12px;
    height: 30px;
    width: 30px;
    border: none;
    background-size: cover;
    background-image: url(${close});
  `;
}

export { sc };
