import { ImageSource, LatLng, Uon } from "@earthtoday/contract";
import { action, computed, flow, makeObservable, observable } from "mobx";
import getConfig from "next/config";
import { TFunction } from "next-i18next";

import { UonCardLocation } from "../components/CardItemTransactionStandard/CardItemTransactionStandard";
import { DynamicLazyModalLoader } from "../components/DynamicModalLoader/DynamicLazyModalLoader";
import { ModalShareGiftDefaultDriver } from "../components/ModalShareGift/ModalShareGiftDefault";
import { ModalStartGivingDriver } from "../components/ModalStartGiving/ModalStartGiving";
import { ITheMessageStore } from "../components/TheMessage/TheMessageStore";
import { IGiftCodeApi } from "../shared/apis/GiftCodeApi";
import { etBrightLightBlue, etGray, etScarletRed } from "../shared/colors";
import { POSITIVE_INTEGER_REGEX_GIVING } from "../shared/constants";
import { buildResizedAwsImageRequest } from "../shared/helpers/buildAwsImageRelated";
import {
  Direction,
  transformDigitToDegrees,
} from "../shared/helpers/coordinates";
import { copyLinkToClipboard } from "../shared/helpers/copyLinkToClipboard";
import {
  detectSharingApp,
  SharingApp,
} from "../shared/helpers/detectSharingApp";
import { getImageUonNextImageForGift } from "../shared/helpers/getImageUonNextImage";
import {
  getOptionBoxUonGift,
  OptionBoxUonGift,
} from "../shared/helpers/getOptionBoxUonGift";
import { navigatorShare } from "../shared/helpers/navigatorShare";
import { translateAPIError } from "../shared/helpers/translateApiError";
import { wait } from "../shared/helpers/wait";
import { snowplowCaptureCodeIssuedEvent } from "../shared/snowplow";
import { CardItemTransactionCollectableModel } from "./CardItemTransactionCollectableModel/CardItemTransactionCollectableModel";
import { CardItemTransactionStandardPresenter } from "./CardItemTransactionStandardPresenter/CardItemTransactionStandardPresenter";
import { IModalStore } from "./ModalStore";
import { ProfilePagePresenter } from "./ProfilePagePresenter";
import { UserModelConnectorDependencies } from "./UserModel";

export const UON_COLLECTIBLE_START_GIVING_WIDTH = 184;
export const UON_COLLECTIBLE_START_GIVING_HEIGHT = 184;
export type CardItemTransactionModel =
  | CardItemTransactionStandardPresenter
  | CardItemTransactionCollectableModel;

export class GivingPresenter
  implements ModalStartGivingDriver, ModalShareGiftDefaultDriver
{
  constructor(
    card: Uon,
    public modalStore: Pick<IModalStore, "openLazyModal" | "closeLazyModal">,
    private userProfile: UserModelConnectorDependencies | null,
    private theMessageStore: Pick<ITheMessageStore, "showMessage">,
    public giftCodeApi: Pick<IGiftCodeApi, "createGiftCode" | "unwrapGiftCode">,
    private flags: {
      giveUonCardEnabled: boolean;
    },
    private isMobileDevice: boolean,
    private statistic: {
      totalUons: number;
      totalBlocks: number;
    },
    public resetState: () => void,
    private profileStore: Pick<
      ProfilePagePresenter,
      | "isLastUonCard"
      | "userCounts"
      | "appendUonsTabData"
      | "filterUonsTabData"
      | "createUonModel"
      | "enableGiftLastUon"
    > | null,
  ) {
    this.card = card;
    makeObservable(this);
  }
  @observable card: Uon;
  @computed get uonReserveSize(): number {
    return this.card.count || 0;
  }
  @computed get point(): LatLng | null {
    return this.card.point || null;
  }
  @computed get coordinates(): string {
    return `${transformDigitToDegrees(
      this.point?.lat || 0,
      Direction.Latitude,
    )} 
    - 
    ${transformDigitToDegrees(this.point?.lng || 0, Direction.Longitude)}`;
  }

  @observable givingCount: number | null = null;
  @action.bound updateGivingCount(n: string): void {
    this.givingCount = +n;
  }
  @observable otherGivingCount: number | null = null;
  @observable giveInputTouched: boolean = false;
  @computed get colorOtherBox(): string {
    if (this.textGivingHelper?.color === etScarletRed) {
      return etScarletRed;
    }
    if (this.giveInputTouched) {
      return etBrightLightBlue;
    }
    return etGray;
  }
  @computed get valueOtherOption(): string {
    return this.otherGivingCount === null ? "" : `${this.otherGivingCount}`;
  }

  @action.bound updateGiveInputTouched = (b: boolean) => {
    this.giveInputTouched = b;
    if (!this.otherGivingCount) {
      this.otherGivingCount = null;
      return;
    }

    if (this.otherGivingCount && this.giveInputTouched) {
      this.givingCount = this.otherGivingCount;
    }
  };
  @computed get isGivingCountValid(): boolean {
    if (!this.givingCount) return false;

    if (this.giveInputTouched && !this.otherGivingCount) {
      return false;
    }
    return (
      this.givingCount <= this.availableUonsCanGift && this.givingCount >= 1
    );
  }

  @computed get isFirstUonCard(): boolean {
    if (
      this.userProfile?.userCounts.uonCount === 0 ||
      this.uonReserveSize === this.userProfile?.userCounts.uonCount
    ) {
      return true;
    }
    return false;
  }

  @computed get uonImageUrl(): string {
    if (this.card.imageSource === ImageSource.UON_IMAGE) {
      const imageData = {
        ...this.card.uonImage,
        awsBucket: this.card.uonImage.bucket,
        awsKey: this.card.uonImage.key,
        url: this.card.uonImage.baseUrl,
        isTransparent: this.card.uonImage.isTransparent ?? null,
      };
      return buildResizedAwsImageRequest(
        imageData,
        {
          width: UON_COLLECTIBLE_START_GIVING_WIDTH,
          height: UON_COLLECTIBLE_START_GIVING_HEIGHT,
        },
        this.card.uonImage.baseUrl,
      );
    }

    return getImageUonNextImageForGift(this.numberOfUon);
  }

  @computed get availableUonsCanGift(): number {
    if (
      this.profileStore &&
      (this.profileStore.isLastUonCard || this.isFirstUonCard)
    ) {
      return this.userProfile?.enableGiftLastUon
        ? this.uonReserveSize
        : this.uonReserveSize - 1;
    }
    return this.uonReserveSize - (this.statistic.totalBlocks === 1 ? 1 : 0);
  }
  @computed get textGivingHelper():
    | { msg: string; color: string; vars?: { length: number } }
    | undefined {
    if (this.otherGivingCount === null) {
      return {
        msg: "modal-gift.max-m2",
        vars: {
          length: this.availableUonsCanGift,
        },
        color: etGray,
      };
    }
    if (
      (this.givingCount === 0 || this.givingCount === null) &&
      this.giveInputTouched
    ) {
      return {
        msg: "modal-gift.min-m2",
        vars: {
          length: this.availableUonsCanGift,
        },
        color: etScarletRed,
      };
    }

    return this.isGivingCountValid || !this.giveInputTouched
      ? {
          msg: "modal-gift.max-m2",
          vars: {
            length: this.availableUonsCanGift,
          },
          color: etGray,
        }
      : {
          msg: "modal-gift.max-gift-m2",
          vars: {
            length: this.availableUonsCanGift,
          },
          color: etScarletRed,
        };
  }

  @computed get numberOfUon(): number {
    return this.givingCount || 1;
  }

  @computed get isEndWithOne(): boolean {
    // eslint-disable-next-line unicorn/prefer-spread
    const splitNumber = String(this.givingCount || "").split("");
    return Number(splitNumber[splitNumber.length - 1]) === Number(1);
  }
  @computed get isEndWithOneUonCard(): boolean {
    // eslint-disable-next-line unicorn/prefer-spread
    const splitNumber = String(this.uonReserveSize).split("");
    return Number(splitNumber[splitNumber.length - 1]) === Number(1);
  }

  @computed get optionGivingCount(): OptionBoxUonGift {
    return getOptionBoxUonGift(this.availableUonsCanGift);
  }

  @computed get isSelectedOption1(): boolean {
    return (
      this.optionGivingCount.option1 === this.givingCount &&
      !this.giveInputTouched
    );
  }
  @computed get isSelectedOption2(): boolean {
    return (
      this.optionGivingCount.option2 === this.givingCount &&
      !this.giveInputTouched
    );
  }
  @computed get isSelectedOption3(): boolean {
    return (
      this.optionGivingCount.option3 === this.givingCount &&
      !this.giveInputTouched
    );
  }
  @action.bound updateOtherGivingCount(n: string): void {
    if (!POSITIVE_INTEGER_REGEX_GIVING.test(n)) {
      this.otherGivingCount = null;
      this.givingCount = null;
      return;
    }

    if (+n === 0) {
      this.otherGivingCount = 0;
      this.givingCount = 0;
      return;
    }

    if (+n < 0) {
      this.otherGivingCount = null;
      this.givingCount = null;
      return;
    }

    this.otherGivingCount = +n;
    this.givingCount = +n;
  }
  @action.bound reset = () => {
    this.giveInputTouched = false;
    this.givingCount = null;
    this.otherGivingCount = null;
  };

  @computed get giftCode(): string {
    return this.card.code || "";
  }

  @computed get giftExpiredAt(): Date | null {
    if (!this.card.giftExpiredAt) {
      return null;
    }

    return new Date(this.card.giftExpiredAt);
  }
  @computed get shouldRenderGiftItem(): boolean {
    if (!this.giftExpiredAt) return false;

    return this.giftExpiredAt > new Date();
  }

  @action.bound unwrapGiftCode = flow(function* (this: GivingPresenter) {
    try {
      if (!this.card.code) {
        return;
      }
      this.isSubmiting = true;
      yield this.giftCodeApi.unwrapGiftCode({
        code: this.card.code,
      });

      this.card.code = "";
      this.card.giftExpiredAt = "";
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    } finally {
      this.isSubmiting = false;
    }
  });

  @computed get userPhoto(): string {
    return this.userProfile?.image || "";
  }
  @computed get userFullName(): string {
    return this.userProfile?.fullName || "";
  }

  @computed get pathSharing(): string {
    const data: Record<string, string> = {
      code: this.giftCode,
    };
    const queryString = new URLSearchParams(data);

    return `${getConfig().publicRuntimeConfig.REACT_APP_HOST}/collect/${
      this.userProfile?.vanityName
    }?${queryString.toString()}`;
  }

  @computed get shareText(): string {
    return "modal-gift.share-text";
  }

  @observable shareActive: boolean = false;
  @action toggleShareActive = async (t: TFunction) => {
    if (!this.giftCode) {
      throw new Error("giftCode should not be empty");
    }

    if (!this.shareActive && this.isMobileDevice) {
      try {
        await navigatorShare({
          url: this.pathSharing,
          text: t(this.shareText),
        });
        return;
      } catch (error) {
        console.error(error);
      }
    }

    this.shareActive = !this.shareActive;
  };

  @observable isSubmiting = false;
  @action.bound createGiftCode = flow(function* (
    this: GivingPresenter,
    t: TFunction,
  ) {
    try {
      this.isSubmiting = true;

      const giftCodeResponse: Uon[] = yield this.giftCodeApi.createGiftCode({
        uonId: this.card.id.toString(),
        wrappedSize: this.givingCount || 1,
      });

      const toUonTransaction = giftCodeResponse.find((uon) => uon.code);
      const fromUonTransaction = giftCodeResponse.find((uon) => !uon.code);

      if (!toUonTransaction) {
        return;
      }

      // capture issued code event
      const issuer =
        toUonTransaction.owner?.vanityName ||
        this.userProfile?.vanityName ||
        "";
      snowplowCaptureCodeIssuedEvent({
        issuer,
        codeType: "gift",
        issueSource: "user_wallet_create_gift_code_modal",
        uonValue: this.givingCount || 1,
      });

      // close from uon modal
      this.modalStore.openLazyModal();
      const uon = this.createUonModel(
        toUonTransaction,
        this.statistic.totalBlocks,
        this.statistic.totalUons,
      );

      uon.openGivingModal({ fadeAnimaion: false });
      yield uon.givingStore.toggleShareActive(t);

      if (fromUonTransaction) {
        this.card = fromUonTransaction;
      } else {
        // to uon card is the same as to from uon
        this.profileStore?.filterUonsTabData(this.card.id);
      }
      this.profileStore?.appendUonsTabData(uon);
    } catch (error) {
      this.theMessageStore.showMessage({
        typeMessage: "Error",
        title: "toast-message.general.error",
        content: translateAPIError(error),
      });
    } finally {
      this.isSubmiting = false;
    }
  });

  createUonModel(
    uon: Uon,
    totalBlocks: number,
    totalUons: number,
  ): CardItemTransactionModel {
    return uon.imageSource === ImageSource.UON_IMAGE
      ? new CardItemTransactionCollectableModel(
          uon,
          this.modalStore,
          this.userProfile,
          this.theMessageStore,
          this.giftCodeApi,
          {
            giveUonCardEnabled: this.flags.giveUonCardEnabled,
          },
          this.isMobileDevice,
          {
            totalBlocks,
            totalUons,
          },
          this.resetState,
          this.profileStore,
          UonCardLocation.WALLET,
        )
      : new CardItemTransactionStandardPresenter(
          uon,
          this.modalStore,
          this.userProfile,
          this.theMessageStore,
          this.giftCodeApi,
          {
            giveUonCardEnabled: this.flags.giveUonCardEnabled,
          },
          this.isMobileDevice,

          {
            totalBlocks,
            totalUons,
          },
          this.resetState,
          this.profileStore,
          UonCardLocation.WALLET,
        );
  }

  @action.bound copyCardLinkToClipboard = flow(
    function* copyCardLinkToClipboard(
      this: GivingPresenter,
      cardUrl: string,
      t: TFunction,
    ) {
      copyLinkToClipboard(cardUrl);
      yield wait(200);
      yield this.toggleShareActive(t);
      this.theMessageStore.showMessage({
        typeMessage: "Close",
        title: "toast-message.link-copied.title",
        content: "toast-message.link-copied.content",
      });
    },
  );

  @action.bound createCodeAndShare = flow(function* createCodeAndShare(
    this: GivingPresenter,
    t: TFunction,
  ) {
    if (!this.card.code) {
      yield this.createGiftCode(t);
      return;
    }

    yield this.toggleShareActive(t);
  });

  @action.bound openGivingModal = (options?: { fadeAnimaion?: boolean }) => {
    if (
      !this.isSelectedOption1 &&
      !this.isSelectedOption2 &&
      !this.isSelectedOption3
    ) {
      this.updateGivingCount(`${this.availableUonsCanGift}`);
    }

    if (this.isSelectedOption1) {
      this.updateGivingCount(`${this.optionGivingCount.option1}`);
    }

    if (this.isSelectedOption2) {
      this.updateGivingCount(`${this.optionGivingCount.option2}`);
    }

    if (this.isSelectedOption3) {
      this.updateGivingCount(`${this.optionGivingCount.option3}`);
    }

    if (this.otherGivingCount && this.giveInputTouched) {
      this.updateGivingCount(`${this.otherGivingCount}`);
    }

    if (this.card.code || this.availableUonsCanGift === 1) {
      this.triggerShare(options);
      return;
    }

    this.modalStore.openLazyModal({
      name: "startGiving",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../components/ModalStartGiving/ModalStartGiving")
          }
          driver={this}
        />
      ),
    });
  };

  @action.bound onConfirmGiving = flow(function* (this: GivingPresenter) {
    this.triggerShare();
  });

  @action.bound triggerShare = flow(function* (
    this: GivingPresenter,
    options?: { fadeAnimaion?: boolean },
  ) {
    if (this.giftCode) {
      this.givingCount = this.uonReserveSize;
    }
    this.modalStore.openLazyModal({
      name: "shareGift",
      component: (
        <DynamicLazyModalLoader
          loadComponent={() =>
            import("../components/ModalShareGift/ModalShareGift")
          }
          driver={this}
          fadeAnimaion={options?.fadeAnimaion}
        />
      ),
    });
  });

  @action.bound detectWhatsapp(t: TFunction) {
    return detectSharingApp(
      t(this.shareText),
      this.pathSharing,
      SharingApp.WHATSAPP,
    );
  }
}
