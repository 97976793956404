import { action, makeObservable } from "mobx";

import { RootStore } from "../../stores/rootStore";

export class LayoutAppPresenter {
  constructor(private rootStore: RootStore) {
    makeObservable(this);
  }

  @action.bound onMount(pageName: string) {
    if (
      this.rootStore.mobileLandingPagePresenter.shouldDisplayMobileLandingPage
    ) {
      return;
    }

    this.rootStore.theNavbarStore.qrCodeOverlayPrimaryStatePresenter.onUserSessionStateUpdated(
      pageName,
      this.rootStore.userSessionStore.didInit,
    );
  }
}
